import { Instance, types } from 'mobx-state-tree';
import { AddressEntryFormData } from '../../main-address/AddressEntryFormData';

export const AddressEntryChoice = types
  .model({
    id: types.identifierNumber,
    address: types.model({ lines: types.array(types.string) }),
    addressForm: AddressEntryFormData
  })
  .named('AddressEntryChoice');

export type AddressEntryChoice = Instance<typeof AddressEntryChoice>;

export const AddressToVerifyResponse = types
  .model({
    originalAddress: AddressEntryChoice,
    recommendations: types.array(AddressEntryChoice),
    isValid: types.boolean
  })
  .named('AddressToVerifyResponse');

export type AddressToVerifyResponse = Instance<typeof AddressToVerifyResponse>;
