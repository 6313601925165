import { getSnapshot, Instance, types } from 'mobx-state-tree';
import { FieldState, FormState } from 'formstate';
import { getFormValues } from '@yl/react-forms/dist';
import { EnrollmentModuleForm } from '../../enrollment-process/module-management/model/EnrollmentModuleForm';
import { PhoneEntryFormData } from './PhoneEntryFormData';
import { required } from '../shared/validators/RequiredValidator';
import { PhoneEntryConfig } from './PhoneEntryConfig';
import { matchesRegExp } from '../shared/validators/RegularExpressionValidator';
import { containsValidCharacters } from '../shared/validators/InternationalPhoneValidator';
import { getRootStore } from '../../root/RootStoreUtils';

export const PhoneEntryStore = types
  .model({
    phoneRegularExpression: types.string,
    form: EnrollmentModuleForm,
    data: PhoneEntryFormData,
    config: PhoneEntryConfig,
    internationalMode: types.boolean
  })
  .actions(self => ({
    updateStoreFromForm(form: any) {
      self.data.primaryPhone = form.primaryPhone;
      self.data.secondaryPhone = form.secondaryPhone;
    }
  }))
  .views(self => ({
    get supportsDisplay(): boolean {
      return true;
    },
    get reactForm() {
      const forms = {} as any;
      const formValues = getSnapshot(self.data) as any;
      const validator = self.internationalMode
        ? containsValidCharacters('phoneNumber')
        : matchesRegExp(self.phoneRegularExpression, 'VerifyPhonePatternError');
      self.form.formFields.forEach(field => {
        const fieldstate = new FieldState(formValues[field.name]);
        if (field.required) {
          fieldstate.validators(required(), validator);
        } else {
          fieldstate.validators(validator);
        }
        forms[field.name] = fieldstate;
      });

      return new FormState(forms);
    },
    get defaultCountryIsoCode(): string {
      return getRootStore(self).enrollmentStatus.countryIso;
    },
    getDataToSubmit() {
      const formData = getFormValues(this.reactForm);
      self.updateStoreFromForm(formData);
      return formData;
    },
    canProceed() {
      return !this.reactForm.hasError;
    }
  }))
  .actions(self => ({
    validateForm() {
      return self.reactForm.validate();
    }
  }));

export type PhoneEntryStore = Instance<typeof PhoneEntryStore>;
