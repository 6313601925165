import { Instance, types } from 'mobx-state-tree';
import { ReferrerReviewStore } from '../order-review/sponsor-enroller-review/ReferrerReviewStore';
import { EnrollmentOrderInfoStore } from './EnrollmentOrderInfoStore';
import { EnrollmentRootStore } from '../root/EnrollmentRootStore';
import { getRootStore } from '../root/RootStoreUtils';

export const SocialLinkEntryStore = types
  .model('SocialLinkEntryStore', {
    enrollmentOrderInfo: types.maybe(EnrollmentOrderInfoStore),
    referrerReview: types.maybe(ReferrerReviewStore),
    consentedToPlacement: types.optional(types.boolean, true),
    showMinimumPvWarning: types.optional(types.boolean, false)
  })
  .views(self => ({
    getDataToSubmit(): any {
      return {};
    },
    canProceed(): boolean {
      return this.validated && this.isMinimumErPvReached;
    },
    get validated(): boolean {
      if (!self.enrollmentOrderInfo!.starterKit && self.enrollmentOrderInfo!.additionalItems.length === 0) return false;
      return true;
    },
    get isMinimumErPvReached(): boolean {
      return !self.enrollmentOrderInfo!.isEnrollingInEr || self.enrollmentOrderInfo!.isMinimumErPvReached;
    },
    get rootStore(): EnrollmentRootStore {
      return getRootStore(self);
    },
    get allItemsAvailable(): boolean {
      return self.enrollmentOrderInfo!.unavailableAdditionalItems.length === 0;
    }
  }))
  .actions(self => ({
    checkMinimumErPv() {
      if (!self.isMinimumErPvReached) {
        this.updateMinimumPvWarning(true);
      }
    },
    updateMinimumPvWarning(value: boolean) {
      self.showMinimumPvWarning = value;
    }
  }));

export type SocialLinkEntryStore = Instance<typeof SocialLinkEntryStore>;
