import { Instance } from 'mobx-state-tree';
import { FieldState, FormState } from 'formstate';
import { required } from '../../../customer-information/shared/validators/RequiredValidator';
import { validateIf } from '../../../customer-information/shared/validators/ValidIf';
import { ErKitStore } from '../../er-kit-selection/model/ErKitStore';

export const ClassicErKitStore = ErKitStore.named('ClassicErKitStore')
  .views(self => ({
    get reactForm() {
      const essentialRewardsConfirmField = new FieldState(self.enrollEssentialRewards || false)
        .disableAutoValidation()
        .validators(validateIf(v => self.hasChosenKit, required()));
      return new FormState({
        essentialRewardsConfirm: essentialRewardsConfirmField
      });
    },
    getDataToSubmit() {
      return {
        isEnrollingInEr: this.reactForm.$.essentialRewardsConfirm.value,
        selectedKitId: self.selectedKit && self.selectedKit.itemId,
        customErType: self.customErType
      };
    },
    canProceed() {
      return self.selectedKit === undefined || (self.selectedKit !== undefined && !this.reactForm.hasError);
    }
  }))
  .actions(self => ({
    validateForm() {
      return self.reactForm.validate();
    }
  }));

export type ClassicErKitStore = Instance<typeof ClassicErKitStore>;
