import { Instance, types } from 'mobx-state-tree';

export const AgreementDocument = types
  .model({
    url: types.string,
    description: types.string
  })
  .named('AgreementDocument');

export type AgreementDocument = Instance<typeof AgreementDocument>;
