import { flow, types, addDisposer } from 'mobx-state-tree';
import { reaction } from 'mobx';
import { Currency } from './Currency';
import { Country } from './Country';
import { CountryLanguages } from './CountryLanguages';
import { Suburb } from './Suburb';
import { enrollmentResource } from '../infrastructure/http/EnrollmentResource';
import { getRootStore } from '../app/root/RootStoreUtils';
import { State } from './State';
import { City } from './City';
import { PostalCode } from './PostalCode';

export const EnrollmentReferenceStore = types
  .model({
    currencies: types.array(Currency),
    isLanguagesLoading: false,
    countries: types.maybe(types.array(Country)),
    countriesWithLanguages: types.maybe(types.array(CountryLanguages)),
    suburbs: types.maybe(types.array(Suburb)),
    cities: types.maybe(types.array(City)),
    postalCodes: types.maybe(types.array(PostalCode))
  })
  .views(self => ({
    getStatesForCountry(isoCode: string): State[] | undefined {
      return self.countries?.find(c => c.isoCode2 === isoCode || c.isoCode3 === isoCode)?.states;
    },
    getCountryWithLanguages(isoCode2: string): CountryLanguages | undefined {
      return self.countriesWithLanguages?.find(c => c.isoCode2 === isoCode2);
    },
    getSuburbs(stateAbbreviation: string): Suburb[] | undefined {
      return self.suburbs?.filter(c => c.stateAbbreviation === stateAbbreviation);
    },
    getCities(): City[] | undefined {
      return self.cities;
    },
    getPostalCodes(): PostalCode[] | undefined {
      return self.postalCodes;
    }
  }))
  .actions(self => ({
    getCountries: flow(function* getCountries(locale) {
      if (!self.countries) {
        const result = yield enrollmentResource.get(`/countries/all-countries/${locale}`);
        self.countries = result.data.countries;
      }
      return self.countries;
    }),
    resetCountries() {
      self.countries = undefined;
    },
    getCountriesWithLanguages: flow(function* getCountriesWithLanguages() {
      if (!self.countriesWithLanguages) {
        const result = yield enrollmentResource.get(`/countries/all-countries`);
        self.countriesWithLanguages = result.data.countries;
      }
      return self.countriesWithLanguages;
    }),
    getAllSuburbs: flow(function* getAllSuburbs() {
      if (!self.suburbs) {
        const result = yield enrollmentResource.get(`/countries/all-suburbs`);
        self.suburbs = result.data;
      }
      return self.suburbs;
    }),
    getAllCities: flow(function* getAllCities(countryIso: string) {
      if (!self.cities) {
        const result = yield enrollmentResource.get(`/countries/${countryIso}/cities`);
        self.cities = result.data;
      }
      return self.cities;
    }),
    getCitiesByCountryAndStateProv: flow(function* getCitiesByCountryAndStateProv(countryIso: string, stateProv: string) {
      const result = yield enrollmentResource.get(`/countries/${countryIso}/states/${stateProv}/cities`);
      self.cities = result.data;
      return self.cities;
    }),
    getPostalCodeByCityAndStateProv: flow(function* getPostalCodeByCityAndStateProv(countryIso: string, stateProv: string, city: string) {
      const result = yield enrollmentResource.get(`countries/${countryIso}/states/${stateProv}/cities/${city}`);
      self.postalCodes = result.data;
      return self.postalCodes;
    })
  }))
  .actions(self => ({
    afterCreate() {
      addDisposer(
        self,
        reaction(
          () => getRootStore(self).localeManager.language.locale,
          () => self.resetCountries()
        )
      );
    }
  }));
