import { comparer, reaction } from 'mobx';

import { enrollmentClient, enrollmentResource } from '../../../../infrastructure/http/EnrollmentResource';
import { FieldState } from 'formstate';
import { ObservableTask } from '../../../../external/shared/utils/ObservableTask';
import {
  SponsorEnrollerClient,
  UplineValidationStatus
} from '../../../../external/shared/api/EnrollmentClient.generated';

export class SponsorEnrollerValidator {
  private readonly apiClient = new SponsorEnrollerClient('', enrollmentClient);

  constructor(
    private readonly sponsorField: FieldState<string>,
    private readonly enrollerField: FieldState<string>,
    public onValidationResult?: (result: UplineValidationStatus) => void
  ) {
    reaction(
      () => [sponsorField.value, enrollerField.value],
      () => {
        if (this.validatorTask.isRunning) {
          this.validatorTask.runOrRestart();
        }
      },
      { equals: comparer.shallow }
    );
  }

  public async validSponsorEnroller() {
    await this.validatorTask.runOrRestart();
    return this.validatorResult && this.validatorResult?.isValid ? undefined : 'invalidSponsorId';
  }

  private validatorResult: UplineValidationStatus | false | undefined;

  private validatorTask = new ObservableTask(
    async cancelToken => {
      const sponsor = this.sponsorField.value;
      const enroller = this.enrollerField.value;
      await new Promise(resolve => setTimeout(resolve, 500));
      if (sponsor !== this.sponsorField.value || enroller !== this.enrollerField.value) {
        return false;
      }
      const response = await enrollmentResource.get<UplineValidationStatus>(
        `/sponsor-enroller-validation?sponsorId=${sponsor}&enrollerId=${enroller}`);
      return response.data;
    },
    result => {
      if (result) {
        this.validatorResult = result;
        this.onValidationResult?.(result);
      }
    }
  );
}
