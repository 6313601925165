import { Instance, types } from 'mobx-state-tree';
import { FormState, FieldState } from 'formstate';
import { required } from '../shared/validators/RequiredValidator';
import { EnrollmentCustomerType } from '../../../external/shared/api/EnrollmentClient.generated';

export const customerTypeMapping = {
  Distributor: 'Distributor' as const,
  PreferredCustomer: 'PreferredCustomer' as const
};

export const CustomerTypeEntryStore = types
  .model({
    currentType: types.string,
    selectedType: types.maybe(types.string),
    availableChoices: types.array(types.string),
    IsSwitched: types.optional(types.boolean, false)
  })
  .views(self => ({
    get reactForm() {
      if (!self.selectedType) {
        self.selectedType = self.currentType;
      }
      return new FormState({
        selectedType: new FieldState(self.selectedType).disableAutoValidation().validators(required())
      });
    },
    canProceed() {
      return !this.reactForm.hasError;
    },
    getDataToSubmit() {
      return { selectedType: this.reactForm.$.selectedType.value };
    },
    getCurrentType() {
      return self.selectedType ?? self.currentType;
    }
  }))
  .actions(self => ({
    validateForm() {
      return self.reactForm.validate();
    },
    changeType(type: string) {
      self.selectedType = type;
      self.IsSwitched = self.selectedType !== self.currentType;
    }
  }))
  .named('CustomerTypeEntryStore');

export type CustomerTypeEntryStore = Instance<typeof CustomerTypeEntryStore>;
