import { Instance, types } from 'mobx-state-tree';
import { AddressEntryFormData } from '../../address/main-address/AddressEntryFormData';

export const ShippingAndBillingReviewStore = types.model({
  shippingMethod: types.maybe(types.string),
  shippingAddress: types.model({ lines: types.array(types.string) }),
  userShippingAddress: AddressEntryFormData,
  billingAddress: AddressEntryFormData,
  editStepNumber: types.number
});

export type ShippingAndBillingReviewStore = Instance<typeof ShippingAndBillingReviewStore>;
