import { Instance, types } from 'mobx-state-tree';
import { FieldState, FormState } from 'formstate';

export const PreferencesEntryStore = types
  .model({
    receiveEmail: types.boolean
  })
  .actions(self => ({
    updateStoreFromForm(formData: any) {
      self.receiveEmail = formData.receiveEmail;
    }
  }))
  .views(self => ({
    get reactForm() {
      return new FormState({
        receiveEmail: new FieldState(self.receiveEmail).disableAutoValidation()
      });
    },
    getDataToSubmit() {
      const form = this.reactForm;
      const formData = {
        receiveEmail: form.$.receiveEmail.value
      };
      self.updateStoreFromForm(formData);
      return formData;
    },
    canProceed() {
      return Promise.resolve(true);
    }
  }))
  .actions(self => ({
    validateForm() {
      return Promise.resolve(true);
    }
  }));

export type PreferencesEntryStore = Instance<typeof PreferencesEntryStore>;
