import { flow, Instance, SnapshotIn, types } from 'mobx-state-tree';
import { enrollmentResource } from '../../../infrastructure/http/EnrollmentResource';
import { AddressEntryChoice, AddressToVerifyResponse } from './models/AddressToVerifyResponse';
import { AddressEntryFormData } from '../main-address/AddressEntryFormData';

export const AddressValidator = types
  .model({
    showModal: types.optional(types.boolean, false),
    recommendations: types.maybe(AddressToVerifyResponse),
    selectedAddress: types.maybe(types.reference(AddressEntryChoice)),
    validated: types.optional(types.boolean, false)
  })
  .volatile(self => ({
    addressValidationDeferred: undefined as (() => void) | undefined
  }))
  .views(self => ({
    get haveRecommendations() {
      return self.recommendations && self.recommendations.recommendations.length > 0;
    },
    get shouldShowModal() {
      const isValid = self.recommendations && self.recommendations.isValid;
      return this.haveRecommendations || !isValid;
    }
  }))
  .actions(self => ({
    validateAddressOnServer: flow(function* validateAddressOnServer(dataToCheck: any) {
      try {
        const response = yield enrollmentResource.post<AddressToVerifyResponse>('address-verification', dataToCheck);
        self.recommendations = response.data;
        return true;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        return false;
      }
    })
  }))

  .actions(self => ({
    chooseAddress(address: AddressEntryChoice) {
      self.selectedAddress = address;
    },
    showAddressVerificationModalIfNecessary() {
      if (!self.shouldShowModal) {
        return Promise.resolve();
      }
      self.showModal = true;
      return new Promise<void>(resolve => {
        self.addressValidationDeferred = resolve;
      });
    },
    hideModal(address?: AddressEntryChoice) {
      self.showModal = false;
      self.selectedAddress = address;
      if (self.addressValidationDeferred) {
        self.addressValidationDeferred();
      }
    }
  }))
  .actions(self => ({
    validateAddress: flow(function* prepareResults(address: SnapshotIn<AddressEntryFormData>) {
      try {
        yield self.validateAddressOnServer(address);
        yield self.showAddressVerificationModalIfNecessary();
        if (!self.selectedAddress) {
          return undefined;
        }
        return {
          ...self.selectedAddress.addressForm,
          isValidated: true
        };
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        // Let them still go through with existing address
        address.isValidated = true;
        return address;
      }
    })
  }));

export type AddressValidator = Instance<typeof AddressValidator>;
