/* eslint-disable react/no-this-in-sfc */
import { flow, Instance, types, clone, getSnapshot, addDisposer } from 'mobx-state-tree';
import { FieldState, FormState, Validator } from 'formstate';
import { autorun } from 'mobx';
import { getFormValues } from '@yl/react-forms/dist';
import { matchesRegExp } from '../../customer-information/shared/validators/RegularExpressionValidator';
import { enrollmentResource } from '../../../infrastructure/http/EnrollmentResource';
import { required } from '../../customer-information/shared/validators/RequiredValidator';
import { PhoneValidation } from '../../customer-information/phone/PhoneValidation';
import { AddressEntryFormData } from '../main-address/AddressEntryFormData';
import { AddressRow } from '../main-address/module-form/AddressRow';
import { AddressValidator } from '../validator/AddressValidator';
import { AddressModuleForm } from '../main-address/module-form/AddressModuleForm';
import { maxByteSize } from '../main-address/validators/max-byte-size';
import { PostalCodeValidation } from '../main-address/PostalCodeValidation';
import { ShippingMethodSelectionStore } from '../../shipping/method-selection/ShippingMethodSelectionStore';
import { getRootStore } from '../../root/RootStoreUtils';
import { PickupPointModalStore, ShowPickupPointResult } from '../../external/PickupPointModalStore';
import { Suburb } from '../../../reference/Suburb';
import { ComponentField } from '../../../infrastructure/dynamic-forms/ComponentField';
import { replaceUnallowedCharacters } from '../../../infrastructure/forms/InputTextFormatter';
import { PersonalInfoReviewViewModel } from '../../../external/shared/api/EnrollmentClient.generated';
import { AddressEntryStore } from '../main-address/AddressEntryStore';

export const CountryInfo = types.model({
  country: types.maybe(types.string),
  isRestricted: types.boolean,
  restrictedStates: types.array(types.string)
});

export const ShippingAddressStore = types
  .model({
    sameAsPrimaryAddress: types.boolean,
    form: AddressModuleForm,
    shouldValidateAddress: types.boolean,
    fieldRegularExpression: types.string,
    postalCodeValidation: types.maybe(PostalCodeValidation),
    phoneValidation: types.maybe(PhoneValidation),
    storeId: types.number,
    lastPostalCode: '',
    shippingAddress: AddressEntryFormData,
    primaryAddress: types.maybe(AddressEntryFormData),
    newStoreId: types.maybe(types.number),
    willCallAddress: types.maybe(AddressEntryFormData),
    pickupPointAddress: types.maybe(AddressEntryFormData),
    ausPostAddress: types.maybe(AddressEntryFormData),
    shipToShopAddress: types.maybe(AddressEntryFormData),
    showAusPostWidget: false,
    pickupPointModal: types.optional(PickupPointModalStore, {}),
    addressValidator: types.optional(AddressValidator, {}),
    askIfSameAsPrimaryAddress: types.boolean,
    triggerCountryChangeAlert: types.boolean,
    showWarning: false,
    originalCountry: types.maybe(CountryInfo),
    isShippingCountryRestricted: types.boolean,
    shippingRestrictedStates: types.array(types.string),
    jpZipNotFound: false
  })
  .views(self => ({
    get supportsDisplay(): boolean {
      return true;
    },
    get reactForm() {
      const forms = {} as any;
      const formValues: Record<string, any> = self.shippingAddress;
      self.form.rows.forEach((row: AddressRow) => {
        row.formFields.forEach(field => {
          const fieldState = new FieldState(formValues[field.name] || '');
          const validators: Validator<any>[] = [];
          if (field.required) {
            validators.push(required());
          }
          if (field.fieldType === 'Input') {
            validators.push(maxByteSize(field.maxLength));
            validators.push(matchesRegExp(self.fieldRegularExpression, 'InvalidAddress'));
          }

          if (field.name === 'postalCode' && self.postalCodeValidation) {
            validators.push(matchesRegExp(self.postalCodeValidation.validRegEx, 'VerifyPostalcodePatternError'));
            validators.push(matchesRegExp(self.postalCodeValidation.allowedCharactersRegEx, 'InvalidZipCode'));
          }

          fieldState.validators(...validators);
          forms[field.name] = fieldState;
        });
      });

      return new FormState(forms);
    },

    get displayingWillCall() {
      return self.willCallAddress !== undefined;
    },
    get displayingAusPost() {
      return self.ausPostAddress !== undefined;
    },
    get displayingPickupPoint() {
      return self.pickupPointAddress !== undefined;
    },
    get displayingShipToShop() {
      return self.shipToShopAddress !== undefined;
    },
    get displayAddressForm() {
      return !(self.sameAsPrimaryAddress || self.willCallAddress || self.pickupPointAddress || self.ausPostAddress || self.shipToShopAddress);
    },
    canProceed() {
      return this.displayAddressForm ? !this.reactForm.hasError : true;
    },
    get nonUserEnteredAddress(): AddressEntryFormData | undefined {
      if (self.pickupPointAddress) {
        return self.pickupPointAddress;
      }
      if (self.willCallAddress) {
        return self.willCallAddress;
      }
      if (self.ausPostAddress) {
        return self.ausPostAddress;
      }
      if (self.shipToShopAddress) {
        return self.shipToShopAddress;
      }
      if (self.askIfSameAsPrimaryAddress && self.sameAsPrimaryAddress) {
        return self.primaryAddress;
      }
      return undefined;
    },
    get userShippingAddress(): AddressEntryFormData {
      if (self.askIfSameAsPrimaryAddress && self.sameAsPrimaryAddress) {
        return self.primaryAddress!;
      }
      return self.shippingAddress;
    },
    get addressForDisplay(): AddressEntryFormData {
      if (this.nonUserEnteredAddress) {
        return this.nonUserEnteredAddress;
      }
      return self.shippingAddress;
    },
    async getSelectedAddress(): Promise<AddressEntryFormData | undefined> {
      if (this.nonUserEnteredAddress) {
        return this.nonUserEnteredAddress;
      }
      await this.reactForm.validate();
      if (!this.reactForm.hasError) {
        return AddressEntryFormData.create(getFormValues(this.reactForm));
      }

      return undefined;
    },

    get isInRestrictedArea() {
      return (
        self.isShippingCountryRestricted ||
        this.reactForm.$.stateProv === undefined ||
        self.shippingRestrictedStates.includes(this.reactForm.$.stateProv.value)
      );
    },

    get hasStoreChangeAlert() {
      if (self.newStoreId === undefined || self.newStoreId === self.storeId) {
        return 0;
      }
      if (self.storeId === 1 && self.newStoreId !== self.storeId) {
        return 1;
      }

      return 2;
    },
    getDataToSubmit() {
      if (self.pickupPointAddress) {
        return {
          isUsingPickupPoint: true,
          address: self.pickupPointAddress
        };
      }
      if (self.ausPostAddress) {
        return {
          isAusPost: true,
          address: self.ausPostAddress
        };
      }
      if(self.shipToShopAddress){
        return {
          isShiptoShop: true,
          address: self.shipToShopAddress
        };
      }
      if (self.sameAsPrimaryAddress) {
        return {
          sameAsPrimaryAddress: true
        };
      }
      const toSubmit = {
        isUsingWillCall: !!self.willCallAddress,
        address: {
          ...getFormValues(this.reactForm),
          isValidated: true
        }
      };
      return toSubmit;
    },
    get isFormDirty() {
      return Object.values(this.reactForm.$ as FieldState<any>).some(x => x.dirty);
    },
    handleSaveResponse: flow(function* handleSaveResponse(): any {
      const root = getRootStore(self);
      yield root.stepsManager.currentStep.fetchViewModel();
    })
  }))
  .actions(self => ({
    handleCountryChange() {
      self.showWarning = self.triggerCountryChangeAlert;
      if (self.triggerCountryChangeAlert || self.sameAsPrimaryAddress) {
        return;
      }

      const root = getRootStore(self);
      if (!root.moduleStores.shippingMethodSelection) {
        return;
      }

      root.moduleStores.shippingMethodSelection.updateWarningState(self.hasStoreChangeAlert);
    },
    preserveOriginalCountry() {
      if (self.originalCountry !== undefined) {
        return;
      }
      self.originalCountry = CountryInfo.create({
        country: self.shippingAddress.country,
        isRestricted: self.isShippingCountryRestricted,
        restrictedStates: getSnapshot(self.shippingRestrictedStates)
      });
    }
  }))
  .actions(self => ({
    applyFormat: flow(function* debounceFormat(field: ComponentField) {
      yield new Promise(r => setTimeout(r, 1000));
      replaceUnallowedCharacters(self.reactForm.$[field.name], field.formatRegexForReplace);
    }),
    validateForm() {
      if (self.displayAddressForm) {
        return self.reactForm.validate();
      }
      return Promise.resolve(true);
    },
    prepareResults: flow(function* prepareResults() {
      if (!self.shouldValidateAddress || !self.displayAddressForm || self.shippingAddress.isValidated) {
        return true;
      }
      const selectedAddress = yield self.addressValidator.validateAddress(getFormValues(self.reactForm));
      if (!selectedAddress) {
        return false;
      }
      self.shippingAddress = AddressEntryFormData.create(selectedAddress);
      return true;
    }),
    toggleSameAsPrimaryAddress() {
      self.sameAsPrimaryAddress = !self.sameAsPrimaryAddress;
      this.onAddressChange();
      self.handleCountryChange();
    },
    UpdateSuburb(suburb: Suburb) {
      self.reactForm.$.postalCode.value = suburb.postalCode;
    },
    UpdateNoSuburb(noSuburb: boolean) {
      if (noSuburb === true) {
        self.reactForm.$.stateProv.value = null;
        self.reactForm.$.suburb.value = null;
        self.reactForm.$.postalCode.value = null;
      }
      this.setAllowCustomSuburb(noSuburb);
    },
    onStateChange(state: string) {
      this.onAddressChange();
      this.queryShippingMethodsIfValid();
    },
    onCountryChange: flow(function* CountryChange(country: string) {
      self.preserveOriginalCountry();
      const result = yield enrollmentResource.get(`address-entry/${country}`);
      const data = result.data;
      self.newStoreId = data.storeId;
      self.shippingAddress.country = country;
      self.isShippingCountryRestricted = data.isShippingRestricted;
      self.shippingRestrictedStates = data.shippingRestrictedStates;
      if (!self.triggerCountryChangeAlert) {
        self.form = data.form;
        self.fieldRegularExpression = data.fieldRegularExpression;
        self.postalCodeValidation = data.postalCodeValidation;
      }

      self.handleCountryChange();
    }),

    cancelCountryChange() {
      self.reactForm.$.country.value = self.originalCountry!.country;
      self.shippingRestrictedStates.replace(getSnapshot(self.originalCountry!.restrictedStates));
      self.shippingAddress.country = self.originalCountry!.country;
      self.isShippingCountryRestricted = self.originalCountry!.isRestricted;
      self.newStoreId = undefined;
      self.showWarning = false;
    },

    setShippingToWillCallAddress(willCallAddress: AddressEntryFormData) {
      self.willCallAddress = clone(willCallAddress);
    },

    unsetShippingToWillCallAddress() {
      self.willCallAddress = undefined;
    },

    setShippingToPickupPointAddress(pickupPointAddress: AddressEntryFormData) {
      self.pickupPointAddress = clone(pickupPointAddress);
      self.shippingAddress = clone(pickupPointAddress);
    },

    unsetShippingToPickupPointAddress() {
      self.pickupPointAddress = undefined;
    },

    setAusPostAddress(ausPostAddress: AddressEntryFormData) {
      self.ausPostAddress = clone(ausPostAddress);
    },

    unsetAusPostAddress() {
      self.ausPostAddress = undefined;
    },
    setShipToShopAddress(shipToShopAddress: AddressEntryFormData) {
      self.shipToShopAddress = clone(shipToShopAddress);
    },

    unsetShipToShopAddress() {
      self.shipToShopAddress = undefined;
    },

    setAusPostAddressState(state: boolean) {
      self.sameAsPrimaryAddress = state;
    },

    setShowAusPostWidget(show: boolean) {
      self.showAusPostWidget = show;
    },

    async queryShippingMethodsIfValid() {
      await this.validateForm();
      if (!self.canProceed()) {
        return;
      }
      const root = getRootStore(self);
      if (!root.moduleStores.shippingMethodSelection) {
        return;
      }

      root.moduleStores.shippingMethodSelection!.reQueryShippingMethodsIfNecessary();
    },

    onInputBlurred() {
      return this.queryShippingMethodsIfValid();
    },
    onAddressChange() {
      const root = getRootStore(self);
      if (root.moduleStores.shippingMethodSelection) {
        self.shippingAddress.isValidated = false;
        root.moduleStores.shippingMethodSelection.clearShippingMethods(self.hasStoreChangeAlert);
      }
    },

    resetFormDirty() {
      return Object.values(self.reactForm.$ as FieldState<any>).forEach(x => {
        x.dirty = false;
      });
    },
    afterCreate() {
      addDisposer(
        self,
        autorun(() => {
          if (self.isFormDirty) {
            this.onAddressChange();
          }
        })
      );
    },

    setAllowCustomSuburb(noSuburb: boolean) {
      self.form.rows.forEach((row: AddressRow) => {
        row.formFields.forEach(field => {
          if (field.name === 'stateProv') {
            field.required = !noSuburb;
          }
          if (field.name === 'suburb') {
            field.required = !noSuburb;
          }
          if (field.name === 'postalCode') {
            field.disabled = !noSuburb;
          }
        });
      });
    }
  }))
  .actions(self => ({
    updateFromForm(data: any) {
      if (self.shippingAddress === undefined) {
        self.shippingAddress = AddressEntryFormData.create();
      }

      self.shippingAddress.street1 = data.street1;
      self.shippingAddress.street2 = data.street2;
      self.shippingAddress.street3 = data.street3;
      self.shippingAddress.city = data.city;
      self.shippingAddress.stateProv = data.stateProv;
      self.shippingAddress.postalCode = data.postalCode;
      self.shippingAddress.country = data.country;
      self.jpZipNotFound = data.jpZipNotFound;
      //optional fields
      if (data.neighborhood) {
        self.shippingAddress.neighborhood = data.neighborhood;
      }
      if (data.houseNumber) {
        self.shippingAddress.houseNumber = data.houseNumber;
      }
      if (data.interiorNumber) {
        self.shippingAddress.interiorNumber = data.interiorNumber;
      }
    },
    changePickupPoint: flow(function* changePickupPoint(): any {
      const address: ShowPickupPointResult = yield self.pickupPointModal.showPickupPointModal();
      if (address) {
        self.setShippingToPickupPointAddress(address);
        if (self.shippingAddress === undefined) {
          self.shippingAddress = AddressEntryFormData.create();
        }

        self.shippingAddress.street1 = address.street1;
        self.shippingAddress.street2 = address.street2;
        self.shippingAddress.street3 = address.street3;
        self.shippingAddress.city = address.city;
        self.shippingAddress.stateProv = address.stateProv;
        self.shippingAddress.postalCode = address.postalCode;
        self.shippingAddress.country = address.country;
        self.jpZipNotFound = false;
        //optional fields
        if (address.neighborhood) {
          self.shippingAddress.neighborhood = address.neighborhood;
        }
        if (address.houseNumber) {
          self.shippingAddress.houseNumber = address.houseNumber;
        }
        if (address.interiorNumber) {
          self.shippingAddress.interiorNumber = address.interiorNumber;
        }
      }
     
    }),
    async tryAutoFillJpFormData(data: any, postalCode: string): Promise<void> {
      const response = await enrollmentResource.get<any>(`/zip-to-address-japan/${postalCode}`);
      data.postalCode = postalCode;

      data.jpZipNotFound = !response.data.found;

      if (response.data.city) {
        data.city = response.data.city;
      }
      if (response.data.prefecture) {
        const states = getRootStore(self).reference.getStatesForCountry("JP");
        const responseStateInUpperCase = response.data.prefecture.toUpperCase();
        let state = states?.find(s => s.name.toUpperCase() == responseStateInUpperCase);

        if (!state){
          state = states?.find(s => responseStateInUpperCase.includes(s.name.toUpperCase()) ||
              s.name.toUpperCase().includes(responseStateInUpperCase));
        }

        if (state) {
          data.stateProv = state.abbreviation;
        }
      }
      if (response.data.street1) {
        data.street1 = response.data.street1;
      }

      this.updateFromForm(data);
    },
  }))
  .actions(self => ({
    tryAutoFillJpData(field: ComponentField) {
      const postalCodeFieldValue = self.reactForm.$[field.name].value;

      if (field.name === 'postalCode' && getRootStore(self).enrollmentStatus.countryIso === 'JP' &&
      postalCodeFieldValue.length >= 7 && postalCodeFieldValue.length <= 8 && postalCodeFieldValue !== self.lastPostalCode) {
        self.lastPostalCode = postalCodeFieldValue;
        self.tryAutoFillJpFormData({ ...getFormValues(self.reactForm) }, postalCodeFieldValue);
      }
    },
  }))

export type ShippingAddressStore = Instance<typeof ShippingAddressStore>;
