import { Instance, types } from 'mobx-state-tree';
import { FieldState, FormState } from 'formstate';
import { matchesRegExp } from '../shared/validators/RegularExpressionValidator';
import { matchesField } from '../shared/validators/FieldMatchValidators';
import { required } from '../shared/validators/RequiredValidator';
import { noDuplicateEmail } from './validators/NoDuplicateEmailValidator';
import { validateIf } from '../shared/validators/ValidIf';

export const EmailEntryStore = types
  .model({
    email: types.string,
    emailRegularExpression: types.string
  })
  .actions(self => ({
    updateStoreFromForm(formData: any) {
      self.email = formData.email;
    }
  }))
  .views(self => ({
    get supportsDisplay(): boolean {
      return true;
    },
    get reactForm() {
      const emailField = new FieldState(self.email).disableAutoValidation().validators(
        required(),
        matchesRegExp(self.emailRegularExpression, 'validation_verifyEmailPattern_email'),
        validateIf(value => value !== self.email, noDuplicateEmail)
      );
      return new FormState({
        email: emailField,
        confirmEmail: new FieldState(self.email)
          .disableAutoValidation()
          .validators(required(), matchesField(emailField, 'validation_verifyEquals_confirmEmail'))
      });
    },
    getDataToSubmit() {
      const form = this.reactForm;
      const formData = {
        email: form.$.email.value
      };
      self.updateStoreFromForm(formData);
      return formData;
    },
    canProceed() {
      return !this.reactForm.hasError;
    }
  }))
  .actions(self => ({
    validateForm() {
      return self.reactForm.validate();
    }
  }));

export type EmailEntryStore = Instance<typeof EmailEntryStore>;
