import { types } from 'mobx-state-tree';
import { FieldState, FormState, Validator } from 'formstate';
import { matchesRegExp } from '../../../shared/validators/RegularExpressionValidator';
import { required } from '../../../shared/validators/RequiredValidator';

export const MexicoCurpTaxEntry = types
  .model({
    curpId: types.maybe(types.string),
    curpIdRegExp: types.string,
    isRequired: types.boolean
  })
  .views(self => ({
    get validators() {
      const validators: Validator<string>[] = [];
      if (self.isRequired) {
        validators.push(required());
      }

      validators.push(matchesRegExp(self.curpIdRegExp));
      return validators;
    },
    get reactForm() {
      return new FormState({
        curpId: new FieldState(self.curpId || '').validators(...this.validators)
      });
    }
  }))
  .named('MexicoCurpTaxEntry');
