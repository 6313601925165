import { Instance, types } from 'mobx-state-tree';
import { Currency } from '../../../reference/Currency';

export const MeoCartItem = types
  .model({
    name: types.string,
    partNumber: types.string,
    price: types.number,
    itemPV: types.number,
    currency: types.reference(Currency),
    quantity: types.number
  })
  .named('MeoCartItem');

export type MeoCartItem = Instance<typeof MeoCartItem>;
