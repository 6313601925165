import startOfMonth from 'date-fns/start_of_month';
import parse from 'date-fns/parse';
import isBefore from 'date-fns/is_before';

export function futureMonthValidator(message?: string) {
  return (value: string) => {
    const currentMonthEnd = startOfMonth(new Date());
    const inputDate = parse(value);
    return isBefore(inputDate, currentMonthEnd) && (message || 'InvalidExpiration');
  };
}

export function combineFieldDates([month, year]: (string | number)[]) {
  return `${year}/${month}`;
}
