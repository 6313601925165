import { enrollmentResource, baseURL } from '../../../infrastructure/http/EnrollmentResource';

interface Enrollment2RedirectLogViewModel {
  Url: string;
  Step: string;
}

export const LogStep = {
  async logStepProgress(step: string) {
    await enrollmentResource.post<Enrollment2RedirectLogViewModel>('redirect-log', {
      Url: baseURL,
      Step: step
    });
  }
};
