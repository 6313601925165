import { Instance, types } from 'mobx-state-tree';
import { FormState, FieldState } from 'formstate';
import { required } from '../shared/validators/RequiredValidator';

export const DirectMarketingAgreementEntryStore = types
  .model('DirectMarketingAgreementEntryStore', {
    agreementAccepted: types.boolean
  })
  .views(self => ({
    get reactForm() {
      return new FormState({
        agreementAccepted: new FieldState(self.agreementAccepted).disableAutoValidation().validators(required())
      });
    },
    canProceed() {
      return !this.reactForm.hasError;
    },
    getDataToSubmit() {
      return {
        agreementAccepted: this.reactForm.$.agreementAccepted.value
      };
    }
  }))
  .actions(self => ({
    validateForm() {
      return self.reactForm.validate();
    },
    setAgreementAccepted(agreementAccepted: FieldState<boolean>) {
      self.agreementAccepted = agreementAccepted.value;
    }
  }));

export type DirectMarketingAgreementEntryStore = Instance<typeof DirectMarketingAgreementEntryStore>;
