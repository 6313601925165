import { Instance, types } from 'mobx-state-tree';

export const InputTypes = ['CheckBox', 'Radio', 'Password', 'Text'] as const;
export type InputType = (typeof InputTypes)[number];

export const FieldTypes = ['Input', 'Select', 'Radio', 'Check', 'TextArea'] as const;
export type FieldType = (typeof FieldTypes)[number];

export const ComponentField = types.model('ComponentField', {
  id: types.maybe(types.string),
  name: types.string,
  fieldType: types.enumeration<FieldType>('FieldTypes', FieldTypes as any),
  labelKey: types.string,
  required: types.boolean,
  disabled: types.boolean,
  inputType: types.enumeration<InputType>('InputTypes', InputTypes as any),
  size: types.optional(types.number, 4),
  maxLength: types.maybe(types.number),
  minLength: types.maybe(types.number),
  formatRegexForReplace: types.maybe(types.string)
});

export type ComponentField = Instance<typeof ComponentField>;
