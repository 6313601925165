import { AxiosResponse } from 'axios';
import { types, flow } from 'mobx-state-tree';

import { EnrollmentRootStore } from '../../root/EnrollmentRootStore';
import { getRootStore } from '../../root/RootStoreUtils';
import { baseURL, enrollmentResource } from '../../../infrastructure/http/EnrollmentResource';
import { Redirector } from '../../../infrastructure/redirecting/Redirector';
import { removeCurrentEnrollmentId } from '../../customer-enrollment-status/EnrollmentStatusManager';
import { setupVoAuthentication } from '../../authentication/VoAuthentication';
import { AuthenticationToken, SocialLinkViewModel } from '../../../external/shared/api/EnrollmentClient.generated';
import { languageDetector } from '../../../external/shared/localization/languageDetector';

export const SocialLinkCreatorEntryStore = types
  .model('SocialLinkCreatorEntryStore', {
    authToken: types.frozen<AuthenticationToken>(),
    socialLinkCopied: types.optional(types.boolean, false),
    templateId: types.maybe(types.string),
    linkName: types.string,
    creatorLocale: types.maybe(types.string)
  })
  .views(self => ({
    get rootStore(): EnrollmentRootStore {
      return getRootStore(self);
    },
    get isSocialLinkCreated(): boolean {
      return !!self.templateId;
    },
    get socialLink(): string {
      return `${window.location.origin}${baseURL}/social-links/${self.templateId}`;
    }
  }))
  .actions(self => ({
    async addShareStatus(status: string): Promise<void> {
      await enrollmentResource.post(`social-links/${self.templateId}/share-statuses/${status}`);
    }
  }))
  .actions(self => ({
    createSocialLink: flow(function* changeStep(ignoreValidation = false): any {
      if (self.rootStore.stepsManager.loadingStep) return false;

      try {
        self.rootStore.spin();
        const canProceed = ignoreValidation ? true : yield self.rootStore.stepsManager.currentStep.saveModuleStates();
        if (!canProceed) {
          self.rootStore.router.setCurrentView('enrollmentStep', {
            stepNumber: self.rootStore.stepsManager.currentStep.id
          });
          return false;
        }
        const { data }: AxiosResponse<SocialLinkViewModel> = yield enrollmentResource.post('social-links');

        self.templateId = data.id;

        return true;
      } finally {
        self.rootStore.unspin();
      }
    }),
    copySocialLink: flow(function* copySocialLink(): any {
      if (self.socialLinkCopied) return;
      navigator.clipboard.writeText(self.socialLink);
      self.socialLinkCopied = true;
      self.addShareStatus('clipboard');
      yield new Promise(resolve => setTimeout(resolve, 1500));
      self.socialLinkCopied = false;
    }),
    redirectToVO(): void {
      if (self.creatorLocale) languageDetector.cacheUserLanguage(self.creatorLocale);
      setupVoAuthentication(self.authToken);
      removeCurrentEnrollmentId();
      Redirector.redirect('/vo/#/social-links');
    }
  }));
