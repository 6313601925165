import { Instance, types } from 'mobx-state-tree';
import { EssentialRewardKit } from '../../order-selection/er-kit-selection/model/EssentialRewardKit';

export const ProductInfoErKitStore = types
  .model('ProductInfoErKitStore', {
    name: types.string,
    imageUrl: types.string,
    largeImageUrl: types.string,
    description: types.maybe(types.string),
    shortDescription: types.maybe(types.string),
    howToUse: types.maybe(types.string),
    ingredients: types.maybe(types.string),
    weight: types.optional(types.number, 0.00),
    partNumber: types.string,
    imageExpanded: types.optional(types.boolean, false),
    kit: types.reference(EssentialRewardKit),
    isMobile: window.innerWidth <= 576
  })
  .actions(self => ({
    expandImage(): void {
      self.imageExpanded = true;
    },
    closeImage(): void {
      self.imageExpanded = false;
    }
  }));

export type ProductInfoErKitStore = Instance<typeof ProductInfoErKitStore>;
