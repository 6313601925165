import { Instance, types } from 'mobx-state-tree';
import { FieldState, FormState, Validator } from 'formstate';
import { matchesRegExp } from '../../../shared/validators/RegularExpressionValidator';
import { required } from '../../../shared/validators/RequiredValidator';
import { noDuplicateTaxIdValidator } from '../../validator/NoDuplicateTaxIdValidator';
import { replaceFieldWithDashes } from '../TaxIdFormatter';

export const IndividualTaxEntry = types
  .model('IndividualTaxEntry', {
    regularExpression: types.string,
    isRequired: types.boolean,
    isUniqueRequired: types.optional(types.boolean, false),
    taxId: types.maybe(types.string),
    taxIdTypeId: types.frozen(types.number),
    mask: types.string,
    name: types.string,
    canCollect: types.boolean
  })
  .views(self => ({
    get validators() {
      const validators: Validator<any>[] = [];
      if (self.isRequired) {
        validators.push(required());
      }

      validators.push(matchesRegExp(self.regularExpression, 'invalidTaxId'));
      if (self.isUniqueRequired) {
        validators.push((value: string) =>
          value !== self.taxId ? noDuplicateTaxIdValidator(value, self.taxIdTypeId) : false
        );
      }
      return validators;
    },
    get reactForm() {
      return new FormState({
        taxId: new FieldState(self.taxId || '').validators(...this.validators)
      });
    },
    get haveValidTaxId() {
      return this.reactForm.$.taxId.value && !this.reactForm.$.taxId.hasError;
    },
    get taxIdField(): FieldState<string> {
      return this.reactForm.$.taxId;
    },
    get requireTaxId(): boolean {
      return self.isRequired;
    }
  }))
  .actions(self => ({
    fixDashes() {
      replaceFieldWithDashes(self.reactForm.$.taxId, self.mask);
    },
    afterCreate() {
      this.fixDashes();
    }
  }));

export type IndividualTaxEntry = Instance<typeof IndividualTaxEntry>;
