import { Instance, types } from 'mobx-state-tree';

export const PaymentInstallment = types
  .model({
    totalAmount: types.number,
    installmentAmount: types.number,
    installmentNumber: types.number,
    installmentKey: types.string,
    installmentPlanId: types.identifierNumber
  })
  .named('PaymentInstallment');

export type PaymentInstallment = Instance<typeof PaymentInstallment>;
