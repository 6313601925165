import { Instance, types } from 'mobx-state-tree';
import { FieldState, FormState } from 'formstate';
import { required } from '../shared/validators/RequiredValidator';
import { validateIf } from '../shared/validators/ValidIf';
import { matchesField } from '../shared/validators/FieldMatchValidators';
import { matchesRegExp } from '../shared/validators/RegularExpressionValidator';

const pinEntryMatch = 'validation_verifyEquals_confirmPin';
const pinMask = '****';
export const PinEntryStore = types
  .model({
    pinRegularExpression: types.string,
    existingAccount: types.boolean
  })
  .actions(self => ({
    updateStoreFromForm() {
      self.existingAccount = true;
    }
  }))
  .views(self => ({
    get supportsDisplay(): boolean {
      return true;
    },
    isMaskedPin(value: string) {
      return self.existingAccount && pinMask === value;
    },
    getPinFromFormOrBlankIfMasked() {
      const pin = this.reactForm.$.pin.value;
      return this.isMaskedPin(pin) ? '' : pin;
    },
    get reactForm() {
      const pinField = new FieldState(self.existingAccount ? pinMask : '').disableAutoValidation().validators(
        required(),
        validateIf(value => !this.isMaskedPin(value), matchesRegExp(self.pinRegularExpression, 'PinError'))
      );
      return new FormState({
        pin: pinField,
        confirmPin: new FieldState(self.existingAccount ? pinMask : '')
          .disableAutoValidation()
          .validators(required(), matchesField(pinField, pinEntryMatch))
      });
    },
    getDataToSubmit() {
      const formData = {
        pin: this.getPinFromFormOrBlankIfMasked()
      };
      self.updateStoreFromForm();
      return formData;
    },
    canProceed() {
      return !this.reactForm.hasError;
    }
  }))
  .actions(self => ({
    validateForm() {
      return self.reactForm.validate();
    }
  }));

export type PinEntryStore = Instance<typeof PinEntryStore>;
