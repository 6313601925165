export class TaskCompletionSource<T> {
  resolve!: (value: T) => void;
  reject!: (error: any) => void;
  readonly task: Promise<T>;
  constructor() {
    this.task = new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }
}
