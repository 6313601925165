import { Instance, types } from 'mobx-state-tree';

export const TaxIdVerificationResponse = types
  .model({
    isValid: types.optional(types.boolean, false),
    finishedValidation: types.optional(types.boolean, false),
    name: types.optional(types.string, ''),
    address: types.optional(types.string, '')
  })
  .named('TaxIdVerificationResponse');

export type TaxIdVerificationResponse = Instance<typeof TaxIdVerificationResponse>;
