import { Instance, types } from 'mobx-state-tree';
import { TaxContextName, TaxIdContext } from './tax-context-types/TaxIdContext';

export const TaxIdEntryStore = types
  .model('TaxIdEntryStore', {
    selectedContext: types.maybe(types.reference(TaxIdContext)),
    taxIdContexts: types.array(TaxIdContext),
    shouldRequireUserInput: types.boolean,
    hasMultipleEntries: types.boolean,
    validated: types.optional(types.boolean, false),
    enteredTaxId: types.maybe(types.string),
    businessName: types.maybe(types.string),
    defaultToIndividual: types.maybe(types.boolean)
  })
  .views(self => ({
    get supportsDisplay(): boolean {
      return true;
    },
    getDefaultContext(contextType: TaxContextName): TaxIdContext | undefined {
      const contexts = self.taxIdContexts.filter(x => x.context === contextType && x.canEnterTaxIds);
      return contexts.length > 0 ? contexts[0] : undefined;
    },
    get defaultBusinessContext(): TaxIdContext | undefined {
      return this.getDefaultContext('Business');
    },
    get defaultIndividualContext(): TaxIdContext | undefined {
      return this.getDefaultContext('Individual');
    },
    get businessContextAvailable(): boolean {
      return this.defaultBusinessContext !== undefined;
    },
    isCurrentContext(contextType: TaxContextName): boolean {
      return self.selectedContext !== undefined && self.selectedContext.context === contextType;
    },
    get selectedTaxIdTypeId(): { taxIdTypeId: number } {
      return {
        taxIdTypeId: self.selectedContext!.selectedEntry!.taxIdTypeId
      };
    },
    getDataToShow() {
      if (self.selectedContext === undefined) {
        return {
          taxId: undefined,
          taxRenderer: undefined,
          businessName: undefined
        };
      }

      const displayData = self.selectedContext!.dataToDisplay;
      if (!displayData.taxId) {
        return {
          taxId: self.enteredTaxId,
          taxRenderer: self.selectedContext.renderer,
          businessName: self.businessName 
        };
      }
      return displayData;
    },
    getDataToSubmit() {
      if (!self.shouldRequireUserInput && self.selectedContext === undefined) {
        return { taxIdTypeId: 0 };
      }

      return {
        taxIdTypeId: self.selectedContext!.selectedEntry!.taxIdTypeId,
        ...self.selectedContext!.taxEntryData,
        contextId: self.selectedContext!.id
      };
    },
    canProceed(): boolean {
      if (self.shouldRequireUserInput && self.selectedContext === undefined) {
        return false;
      }
      if (self.selectedContext !== undefined) {
        return self.selectedContext.canProceed();
      }
      return true;
    },
    get showRequiredError() {
      return self.shouldRequireUserInput && self.validated && self.selectedContext === undefined;
    }
  }))
  .actions(self => ({
    validateForm() {
      self.validated = true;
      return self.selectedContext
        ? self.selectedContext!.validateForm()
        : Promise.resolve({ hasError: self.shouldRequireUserInput });
    },
    selectTaxContext(context: TaxIdContext) {
      self.selectedContext = context;
    },
    toggleBusinessOrIndividual() {
      if (self.isCurrentContext('Business')) {
        this.selectTaxContext(self.defaultIndividualContext!);
      } else {
        this.selectTaxContext(self.defaultBusinessContext!);
      }
    },
    setDefaultContextIfNecessary(): void {
      if (!self.selectedContext || self.selectedContext.id === 'Individual-OptOut') {
        this.selectTaxContext(self.defaultIndividualContext!);
      }
    }
  }));

export type TaxIdEntryStore = Instance<typeof TaxIdEntryStore>;
