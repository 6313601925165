import { flow, Instance, types } from 'mobx-state-tree';

import { EnrollmentPaymentTypes, EnrollmentPaymentType } from '../../model/EnrollmentPaymentTypes';
import { enrollmentClient } from '../../../../infrastructure/http/EnrollmentResource';
import { getRootStore } from '../../../root/RootStoreUtils';
import { InstallmentClient } from '../../../../external/shared/api/EnrollmentClient.generated';
import { PaymentInstallment } from './PaymentInstallment';

export const installmentClient = new InstallmentClient('', enrollmentClient);

export const InstallmentStore = types
  .model({
    enabledPaymentTypes: types.array(types.enumeration('EnrollmentPaymentType', EnrollmentPaymentTypes)),
    showInstallmentsModal: false,
    availableInstallments: types.maybe(types.array(PaymentInstallment)),
    selectedInstallment: types.maybe(types.reference(PaymentInstallment))
  })
  .views(self => ({
    get isEnabled(): boolean {
      const rootStore = getRootStore(self);
      return self.enabledPaymentTypes.includes(rootStore.moduleStores.billingEntry!.selectedPaymentType);
    },
    get isInstallmentsLoading() {
      return !self.availableInstallments && this.isEnabled;
    },
    get haveInstallments() {
      return self.availableInstallments && self.availableInstallments.length > 1;
    },
    canProceed() {
      if (!this.isEnabled) {
        return true;
      }
      const rootStore = getRootStore(self);
      if (rootStore.moduleStores.billingEntry!.selectedPaymentType === EnrollmentPaymentType.CreditCard) {
        return true;
      }
      if (this.isInstallmentsLoading || (this.haveInstallments && !self.selectedInstallment)) {
        return false;
      }
      return true;
    }
  }))
  .actions(self => ({
    reset() {
      self.selectedInstallment = undefined;
      self.availableInstallments = undefined;
    },
    setShowInstallmentsModal(value: boolean) {
      self.showInstallmentsModal = value;
    },
    getAvailableInstallments: flow(function* getAvailableInstallments() {
      self.availableInstallments = self.isEnabled ? yield installmentClient.getAvailableInstallments() : undefined;
    })
  }))
  .actions(self => {
    return {
      handleSaveResponse: flow(function* handleSaveResponse() {
        if (!self.selectedInstallment && self.isEnabled) {
          yield self.getAvailableInstallments();
          if (self.haveInstallments) {
            self.setShowInstallmentsModal(true);
            return false;
          }
        }
        return true;
      }),
      changeSelectedInstallment(installment: PaymentInstallment) {
        self.selectedInstallment = installment;
      }
    };
  });

export type InstallmentStore = Instance<typeof InstallmentStore>;
