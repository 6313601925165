import { getParentOfType, Instance, types } from 'mobx-state-tree';
import { UpgradeComponentInfo } from './UpgradeComponentInfo';
import { Currency } from '../../../../reference/Currency';
import { EnrollmentKitSelectionStore } from './EnrollmentKitStore';

export const ProductKit = types
  .model({
    itemId: types.identifierNumber,
    partNumber: types.string,
    description: types.maybe(types.string),
    smallImageUrl: types.optional(types.string, ''),
    largeImageUrl: types.optional(types.string, ''),
    title: types.maybe(types.string),
    listItems: types.array(types.string),
    details: types.array(types.string),
    currency: types.reference(Currency),
    price: types.number,
    pointValue: types.number,
    upgradeComponent: types.maybeNull(UpgradeComponentInfo)
  })
  .views(self => ({
    isSelected(): boolean {
      return getParentOfType(self, EnrollmentKitSelectionStore).selectedKit === self;
    },
    get priceWithCurrency(): { price: number; currency: Currency } {
      return {
        price: self.price,
        currency: self.currency
      };
    }
  }));
export type ProductKit = Instance<typeof ProductKit>;
