import { Instance, types } from 'mobx-state-tree';
import { FieldState, FormState, Validator } from 'formstate';

export const StudentEntryStore = types
  .model({
    isStudent: types.optional(types.boolean, false)
  })
  .views(self => ({
    get reactForm() {
      return new FormState({
        isStudent: new FieldState(self.isStudent)
      });
    },
    get showStudentError() {
      return self.isStudent;
    },
    getDataToSubmit() {
      return {
        isStudent: self.isStudent
      };
    },
    canProceed() {
      return !self.isStudent;
    }
  }))
  .actions(self => ({
    setIsStudent(isStudent: boolean) {
      self.isStudent = isStudent;
    }
  }));

export type StudentEntryStore = Instance<typeof StudentEntryStore>;
