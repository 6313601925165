import debounce from 'lodash/debounce';
import { enrollmentResource } from '../../../../infrastructure/http/EnrollmentResource';

const validateOnServer = debounce(async (userName: string, resolve: (message: string) => void) => {
  const response = await enrollmentResource.post('/account/verify-username', { userName });

  resolve(response.data && 'UniqueUserNameError');
}, 500);

export const noDuplicateUserName = async (userName: string) => {
  return new Promise<string>(resolve => {
    validateOnServer(userName.trim(), resolve);
  });
};
