import { Instance, types } from 'mobx-state-tree';
import { FieldState } from 'formstate';
import { TaxIdEntryOption } from '../supporting-models/TaxIdEntryOption';

const taxIdContextOptions = ['Individual', 'Business', 'Unknown'] as const;
export type TaxContextName = (typeof taxIdContextOptions)[number];

export const TaxIdContext = types
  .model({
    id: types.identifier,
    entries: types.array(TaxIdEntryOption),
    selectedEntryId: types.maybe(types.number),
    context: types.enumeration<TaxContextName>('context', taxIdContextOptions as any),
    renderer: types.enumeration('renderer', [
      'IndividualOptOut',
      'IndividualStandard',
      'IndividualMultiple',
      'IndividualMexico',
      'BusinessStandard',
      'BusinessMultiple',
      'BusinessMexico',
      'BusinessEU',
      'Unknown'
    ])
  })
  .views(self => ({
    get canEnterTaxIds(): boolean {
      return self.entries.some(x => {
        return x.business !== undefined || x.individual !== undefined || x.mexicoCurp !== undefined;
      });
    },
    get contextField() {
      return new FieldState(self.selectedEntryId ? self.selectedEntryId : 0);
    },
    get selectedEntry(): TaxIdEntryOption | undefined {
      return self.entries.find(x => x.taxIdTypeId === self.selectedEntryId);
    },
    get collectMexicoCurp() {
      return this.selectedEntry && this.selectedEntry.mexicoCurp;
    },
    get dataToDisplay() {
      const data = this.taxEntryData;
      return {
        taxId: data.taxId,
        taxRenderer: undefined,
        businessName: data.businessName
      };
    },
    get taxEntryData() {
      const entry = this.selectedEntry;
      if (entry === undefined) {
        return {};
      }
      if (entry.mexicoCurp) {
        return {
          taxIdTypeId: entry.taxIdTypeId,
          contextId: self.id,
          taxId: entry.individual!.reactForm.$.taxId.value,
          mexicoCurpId: entry.mexicoCurp.reactForm.$.curpId.value
        };
      }
      if (entry.individual) {
        return {
          taxIdTypeId: entry.taxIdTypeId,
          contextId: self.id,
          taxId: entry.individual.reactForm.$.taxId.value
        };
      }
      if (entry.business) {
        return {
          taxIdTypeId: entry.taxIdTypeId,
          contextId: self.id,
          taxId: entry.business.reactForm.$.taxId.value,
          businessName: entry.business.reactForm.$.businessName.value,
          businessLicenseId: entry.business.reactForm.$.businessLicenseId.value,
          isGSTRegistered: entry.business!.businessEUTax!.fullAddressVerified || false,
          businessAddress: entry.business!.businessEUTax!.businessAddress
        };
      }
      return {};
    },
    canProceed(): boolean {
      const entry = this.selectedEntry!;
      const modelsToCheck = [entry.individual, entry.business, entry.mexicoCurp];
      const anyModelHasError = modelsToCheck.some(model => model && model.reactForm.hasError);
      return !anyModelHasError;
    }
  }))
  .actions(self => ({
    selectEntry(entry: number) {
      const previousEntry = self.selectedEntry || self.entries[0];
      self.selectedEntryId = entry;
      if (previousEntry && previousEntry.individual && self.selectedEntry && self.selectedEntry.individual) {
        self.selectedEntry.individual.taxId = previousEntry.individual.reactForm.$.taxId.value;
      }
    },
    async validateForm() {
      const entry = self.selectedEntry!;
      const modelsToValidate = [entry.individual, entry.business, entry.mexicoCurp];
      const validationPromises = modelsToValidate
        .filter(model => model !== undefined)
        .map(model => {
          return model!.reactForm.validate() as Promise<any>;
        });
      return Promise.all(validationPromises);
    }
  }))
  .named('TaxIdContext');

export type TaxIdContext = Instance<typeof TaxIdContext>;
