import { Instance, types } from 'mobx-state-tree';
import { ProductKit } from '../../order-selection/starter-kit-selection/model/ProductKit';

export const ProductInfoStore = types
  .model('ProductInfoStore', {
    name: types.string,
    imageUrl: types.string,
    largeImageUrl: types.string,
    description: types.maybe(types.string),
    shortDescription: types.maybe(types.string),
    howToUse: types.maybe(types.string),
    ingredients: types.maybe(types.string),
    partNumber: types.string,
    weight: types.optional(types.number,0.00),
    imageExpanded: types.optional(types.boolean, false),
    kit: types.reference(types.late(() => ProductKit)),
    isMobile: window.innerWidth <= 576
  })
  .views(self => ({}))
  .actions(self => ({
    expandImage(): void {
      self.imageExpanded = true;
    },
    closeImage(): void {
      self.imageExpanded = false;
    }
  }));

export type ProductInfoStore = Instance<typeof ProductInfoStore>;
