import { Instance, types, flow, addDisposer } from 'mobx-state-tree';
import { Router } from '@yl/react-router';
import { autorun } from 'mobx';
import { EnrollmentStep } from './EnrollmentStep';
import { EnrollmentRoutes } from '../../../routing/routes';
import { EnrollmentRootStore } from '../../../root/EnrollmentRootStore';
import { getRootStore } from '../../../root/RootStoreUtils';
import { LogStep } from '../LogStep';
import { StepLayout } from '../../../../external/shared/api/EnrollmentClient.generated';
import { enrollmentResource } from '../../../../infrastructure/http/EnrollmentResource';
import { useRootStore } from '../../../root/RootStoreHooks';

export const StepsManagerStore = types
  .model('StepsManagerStore', {
    currentStep: types.reference(EnrollmentStep),
    stepLoaded: types.optional(types.boolean, false),
    loadingStep: false,
    canContinue: true,
    steps: types.array(EnrollmentStep)
  })
  .views(self => ({
    get isPaymentFailureLayout(): boolean {
      return self.currentStep.layout === StepLayout.PaymentFailure;
    }
  }))
  .views(self => ({
    get rootStore(): EnrollmentRootStore {
      return getRootStore(self);
    },
    get router(): Router<EnrollmentRoutes> {
      return this.rootStore.router as Router<EnrollmentRoutes>;
    },
    get currentStepNumber(): number {
      return self.steps.indexOf(self.currentStep);
    },
    get isLastStep(): boolean {
      const lastStep = self.steps[self.steps.filter(step => step.label !== 'ReEnterPaymentStep').length - 1];
      return self.currentStep === lastStep;
    },
    get isFirstStep(): boolean {
      return self.currentStep === self.steps[0];
    },
    get isAccordionLayout(): boolean {
      return self.currentStep.layout === StepLayout.Accordion;
    },
   
    isPreviousStep(step: EnrollmentStep): boolean {
      if (!self.currentStep) {
        return false;
      }
      if (self.isPaymentFailureLayout) {
         return false;
      }
      return step.id < self.currentStep.id;
    },
    checkIfCanContinue: flow(function* checkIfCanContinue(): any {
      const url = `/read-enrollment-order-choices`;
      const response = yield enrollmentResource.get(url);
      if (getRootStore(self).enrollmentStatus.countryIso === 'JP') {
        return response.data.additionalOrderItems?.length > 0;
      }
      else {
        return true;
      }
    }),
  }))
  .actions(self => ({
    resetCanProceed() {
      self.canContinue = true;
    },
  }))
  .actions(self => ({
    changeStep: flow(function* changeStep(step: EnrollmentStep, ignoreValidation = false): any {
      if (self.loadingStep) {
        // eslint-disable-next-line no-console
        console.log('Aborting load step because we are already loading');
        return false;
      }

      try {
        self.loadingStep = true;
        self.rootStore.spin();
        if (!self.isPreviousStep(step) && self.currentStep && self.currentStep !== step) {
          const canProceed = ignoreValidation ? true : yield self.currentStep.saveModuleStates();
          if (!canProceed) {
            self.rootStore.router.setCurrentView('enrollmentStep', { stepNumber: self.currentStep.id });
            return false;
          }
        }

        if (!self.isPreviousStep(step)) {
          LogStep.logStepProgress(step.label);
        }
        self.currentStep = step;
        self.stepLoaded = false;
        self.rootStore.router.setCurrentView('enrollmentStep', { stepNumber: step.id });
        yield self.currentStep.fetchViewModel();
        self.stepLoaded = true;
        return true;
      } finally {
        self.loadingStep = false;
        self.rootStore.unspin();
      }
    })
  }))
  .actions(self => ({
    goToStep(id: number): Promise<unknown> {
      const step = self.steps.find(x => x.id === id);
      return self.changeStep(step!);
    },
    nextStep: flow(function* nextStep() {
      self.canContinue = yield self.checkIfCanContinue();
      if (!self.canContinue) {
        return false;
      }
      if (self.isLastStep) {
        return true;
      }
      return yield self.changeStep(self.steps[self.currentStepNumber + 1]);
    }),
    previousStep() {
      if (self.isFirstStep) {
        return;
      }
      self.changeStep(self.steps[self.currentStepNumber - 1], true);
      self.rootStore.router.setCurrentView('enrollmentStep', { stepNumber: self.currentStep.id });
    }
  }));

export function hookRouterToStepManager(store: EnrollmentRootStore) {
  if (store.router.currentView !== 'enrollmentStep') {
    store.stepsManager.goToStep(1);
  }
  addDisposer(
    store.stepsManager,
    autorun(() => {
      if (store.router.currentView === 'enrollmentStep') {
        const { stepNumber } = store.router.getParameters();

        store.stepsManager.goToStep(Number(stepNumber));
      }
    })
  );
}

export type StepsManagerStore = Instance<typeof StepsManagerStore>;
