import { flow, Instance, types } from 'mobx-state-tree';
import { FieldState, FormState } from 'formstate';
import { getFormValues } from '@yl/react-forms';

import { required } from '../../customer-information/shared/validators/RequiredValidator';
import { SepaFormModel } from '../../../external/shared/api/EnrollmentClient.generated';

export const Sepa = types
  .model({
    displayOnly: types.optional(types.boolean, false),
    firstNameOnAccount: types.maybe(types.string),
    lastNameOnAccount: types.maybe(types.string),
    bic: types.maybe(types.string),
    iban: types.maybe(types.string),
    isDefaultPaymentMethod: types.maybe(types.boolean),
    agreementAccepted: types.optional(types.boolean, false)
  })
  .views(self => ({
    get reactForm() {
      const fields: { [key: string]: FieldState<any> } = {
        firstNameOnAccount: new FieldState(self.firstNameOnAccount || '').validators(required()),
        lastNameOnAccount: new FieldState(self.lastNameOnAccount || '').validators(required()),
        bic: new FieldState(self.bic || '').validators(required()),
        iban: new FieldState(self.iban || '').validators(required()),
        agreementAccepted: new FieldState(self.agreementAccepted).validators(required())
      };

      return new FormState(fields);
    },
    canProceed() {
      if (self.displayOnly) {
        return true;
      }
      return !this.reactForm.hasError;
    }
  }))
  .actions(self => ({
    prepareResults: flow(function* prepareResults() {
      if (self.displayOnly) {
        return Promise.resolve(true);
      }
      yield self.reactForm.validate();
      return !self.reactForm.hasError;
    }),
    getDataToSubmit() {
      const form = getFormValues(self.reactForm);
      return {
        firstNameOnAccount: form.firstNameOnAccount,
        lastNameOnAccount: form.lastNameOnAccount,
        bic: form.bic,
        iban: form.iban,
        agreementAccepted: form.agreementAccepted
      } as SepaFormModel;
    },
  }));

export type Sepa = Instance<typeof Sepa>;
