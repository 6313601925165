import { Instance, types } from 'mobx-state-tree';
import { FormState, FieldState } from 'formstate';

export const OrderReturnPolicyReviewStore = types
  .model({
    acceptReview: types.optional(types.boolean, false),
    isErrorModalOpen: false,
    isModalOpen: false
  })
  .views(self => ({
    get reactForm() {
      const acceptReviewField = new FieldState(self.acceptReview);
      return new FormState({
        acceptReviewFormField: acceptReviewField
      });
    },
    haveAcceptedOrderReview() {
      const form = this.reactForm;
      return form.$.acceptReviewFormField.value;
    }
  }))
  .actions(self => ({
    showErrorModal(value = true) {
      self.isErrorModalOpen = value;
    },
    showModal(value = true) {
      self.isModalOpen = value;
    }
  }));

export type OrderReturnPolicyReviewStore = Instance<typeof OrderReturnPolicyReviewStore>;
