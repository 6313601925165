import { EnrollmentPaymentType } from '../../../external/shared/api/EnrollmentClient.generated';
export { EnrollmentPaymentType } from '../../../external/shared/api/EnrollmentClient.generated';

export const EnrollmentPaymentTypes = Object.values(EnrollmentPaymentType);

export function getPaymentTypeLabelName(paymentType: EnrollmentPaymentType) {
  switch (paymentType) {
    case EnrollmentPaymentType.CreditCard:
      return 'CreditCard';
    case EnrollmentPaymentType.WireTransfer:
      return 'WireTransfer';
    case EnrollmentPaymentType.VirtualWalletPaypal:
      return 'PayPal';
    case EnrollmentPaymentType.VirtualWalletIdeal:
      return 'Ideal';
    case EnrollmentPaymentType.Sepa:
      return 'Sepa';
    case EnrollmentPaymentType.Eft:
      return 'Eft';
    case EnrollmentPaymentType.Placetopay:
      return 'Placetopay';
    case EnrollmentPaymentType.Payflex:
      return 'Payflex';
    case EnrollmentPaymentType.Cod:
      return 'CashOnDelivery';
    default:
      return paymentType;
  }
}

export function isEnrollmentPaymentType(value: string): value is EnrollmentPaymentType {
  return EnrollmentPaymentTypes.includes(value as EnrollmentPaymentType);
}
