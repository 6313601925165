import { Instance, flow, types } from 'mobx-state-tree';

import { ThreeDSecureConfigViewModel } from '../../../../../external/shared/api/EnrollmentClient.generated';
import { Braintree3dsStore } from './braintree-3ds/Braintree3dsStore';
import { Cybersource3dsStore } from './cybersource-3ds/Cybersource3dsStore';
import { Chase3dsStore } from './chase-3ds/Chase3dsStore';
import { Stripe3dsStore } from './stripe-3ds/Stripe3dsStore';

export const ThreeDSecureEntryStore = types
  .model({
    config: types.frozen<ThreeDSecureConfigViewModel>(),
    braintree3ds: types.optional(Braintree3dsStore, {}),
    cybersource3ds: types.optional(Cybersource3dsStore, {}),
    chase3ds: types.optional(Chase3dsStore, {}),
    stripe3ds: types.optional(Stripe3dsStore, {})
  })
  .views(self => ({
    get isEnabled() {
      return (
        self.braintree3ds.isEnabled ||
        self.cybersource3ds.isEnabled ||
        self.chase3ds.isEnabled ||
        self.stripe3ds.isEnabled
      );
    }
  }))
  .actions(self => ({
    authenticate: flow(function* authenticate(): any {
      const { braintree3ds, cybersource3ds, chase3ds, stripe3ds } = self;
      if (braintree3ds.isEnabled) {
        yield braintree3ds.authenticate();
      } else if (cybersource3ds.isEnabled) {
        yield cybersource3ds.authenticate();
      } else if (chase3ds.isEnabled) {
        yield chase3ds.authenticate();
      } else if (stripe3ds.isEnabled) {
        yield stripe3ds.authenticate();
      }

    })
  }));

export type ThreeDSecureEntryStore = Instance<typeof ThreeDSecureEntryStore>;
