import { Instance, types } from 'mobx-state-tree';

export const NameEntryFormData = types.model('NameEntryFormData', {
  firstName: types.string,
  middleName: types.string,
  lastName: types.string,
  lastNameKana: types.string,
  firstNameKana: types.string,
  lastNameRomaji: types.string,
  firstNameRomaji: types.string,
});

export type NameEntryFormData = Instance<typeof NameEntryFormData>;
