import { Instance, types } from 'mobx-state-tree';
import { FieldState, FormState } from 'formstate';
import differenceInYears from 'date-fns/difference_in_years';
import { required } from '../shared/validators/RequiredValidator';

export const AgeEntryStore = types
  .model({
    birthYear: types.maybe(types.number),
    birthMonth: types.maybe(types.number),
    birthDay: types.maybe(types.number),
    confirmUserAtLeast18: types.boolean,
    requiredMinimumAge: types.maybe(types.number),
    isRequired: types.maybe(types.boolean),
    isAgeCheckBoxSelected: types.optional(types.boolean,false)
  })
  .actions(self => ({
    updateStoreFromForm(formData: any) {
      self.birthYear = formData.birthYear;
      self.birthMonth = formData.birthMonth;
      self.birthDay = formData.birthDay;
      self.isAgeCheckBoxSelected=formData?.isAgeCheckBoxSelected;
    }
  }))
  .views(self => ({
    isFormEmpty(form: any) {
      const year = form.$.birthYear || 0;
      const month = form.$.birthMonth || 0;
      const day = form.$.birthDay || 0;
      return Number(year) <= 0 && Number(month) <= 0 && Number(day) <= 0;
    }
  }))
  .views(self => ({
    get supportsDisplay(): boolean {
      return true;
    },
    get reactForm() {
      const forms: any = {};
      const yearField = new FieldState(self.birthYear || '');
      const monthField = new FieldState(self.birthMonth || '');
      const dayField = new FieldState(self.birthDay || '');
      if (self.isRequired) {
        yearField.validators(required());
        monthField.validators(required());
        dayField.validators(required());
      }

      forms.date = new FormState({
        year: yearField,
        month: monthField,
        day: dayField
      })
        .compose()
        .validators($ => {
          if (self.isFormEmpty({ $ }) && !self.isRequired) {
            return false;
          }

          const year = Number($.year.$);
          const month = Number($.month.$) - 1;
          const day = Number($.day.$);
          const inputDate = new Date(year, month, day);
          const invalid =
            inputDate.getFullYear() !== year || inputDate.getMonth() !== month || inputDate.getDate() !== day;
          if (invalid && !self.isRequired) {
            return invalid && 'validation_date_dateOfBirth_notRequired';
          }
          else if(invalid){
            return invalid && 'validation_date_dateOfBirth';
          }

          const lessThanRequiredAge = self.requiredMinimumAge
            ? Math.abs(differenceInYears(inputDate, new Date())) < self.requiredMinimumAge
            : false;
          return lessThanRequiredAge && 'tooYoungError';
        });

      if (self.confirmUserAtLeast18) {
        const isAgeCheckBoxSelectedField = new FieldState(self.isAgeCheckBoxSelected|| false).disableAutoValidation().validators(required());
        forms.confirm =isAgeCheckBoxSelectedField;
      }

      return new FormState(forms);
    },

    getDataToSubmit() {
      const form = this.reactForm;

      if (self.isFormEmpty(form) && !self.isRequired) {
        return { birthdate: null };
      }

      self.updateStoreFromForm({
        birthYear: +form.$.date.$.year.value,
        birthMonth: +form.$.date.$.month.value,
        birthDay: +form.$.date.$.day.value,
        isAgeCheckBoxSelected: form?.$?.confirm?.value
      });

      return {
        birthdate: new Date(Date.UTC(Number(form.$.date.$.year.$), Number(form.$.date.$.month.$) - 1, Number(form.$.date.$.day.$))),
        isAgeCheckBoxSelected: new Boolean(form?.$?.confirm?.$)
      };
    },
    canProceed() {
      return !this.reactForm.hasError;
    }
  }))
  .actions(self => ({
    validateForm() {
      if (!self.isRequired && self.isFormEmpty(self.reactForm.$.date)) {
        return Promise.resolve({ hasError: false });
      }

      return self.reactForm.validate();
    },
    confirm() {
      self.isAgeCheckBoxSelected = true;
    }
  }));

export type AgeEntryStore = Instance<typeof AgeEntryStore>;
