import { FieldState } from 'formstate';

export function postalCodeFormatter(
  field: FieldState<string>,
  countryIso: string
) {
    const fieldValue = field.value.replace(/\s/g, '');
    let formattedValue = '';

    if (countryIso === 'SE') {
        for (let i = 0; i < fieldValue.length; i++) {
            if (i > 0 && i % 3 === 0) {
                formattedValue += ' ';
            }
            formattedValue += fieldValue[i];
        }

        field.value = formattedValue;
    }
}