import { Instance, types } from 'mobx-state-tree';

export const PersonalInfoReviewStore = types.model({
  firstName: types.string,
  middleName: types.optional(types.string, ''),
  lastName: types.string,
  email: types.string,
  primaryPhone: types.optional(types.string, ''),
  primaryAddress: types.model({ lines: types.array(types.string) }),
  taxId: types.maybe(types.string),
  editStepNumber: types.number
});

export type PersonalInfoReviewStore = Instance<typeof PersonalInfoReviewStore>;
