/* eslint-disable no-cond-assign */
import { FieldState } from 'formstate';

const separatorExp = /(-|\.)/g;

interface DashIndexMatch {
  idx: number;
  separator: string;
}
function getDashIndexes(taxId: string) {
  const indexes = [] as DashIndexMatch[];
  let match;
  while ((match = separatorExp.exec(taxId)) !== null) {
    indexes.push({ idx: match.index, separator: match[1] });
  }
  return indexes;
}

function shouldRemoveDashes(mask: string) {
  return !mask.includes('?-');
}

function insertDashes(string: string, dashIndexes: DashIndexMatch[]) {
  const stringParts = [];
  let lastSlice = 0;
  const currentLength = string.length;

  for (let matchNum = 0; matchNum < dashIndexes.length; matchNum++) {
    const match = dashIndexes[matchNum];
    if (match.idx > currentLength + matchNum) {
      break;
    }
    stringParts.push(string.slice(lastSlice, match.idx - matchNum));
    stringParts.push(match.separator);
    lastSlice = match.idx - matchNum;
  }
  stringParts.push(string.slice(lastSlice));
  return stringParts.join('');
}

export function replaceFieldWithDashes(field: FieldState<string>, mask: string) {
  if (field.value.length === 0) {
    return;
  }
  const dashIndexes = getDashIndexes(mask);
  if (mask.length > 0 && shouldRemoveDashes(mask)) {
    const withoutDashes = field.value.replace(separatorExp, '');
    const newValue = insertDashes(withoutDashes, dashIndexes);
    if (newValue !== field.value) {
      field.value = newValue;
    }
  }
}
