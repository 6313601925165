import { flow, Instance, types } from 'mobx-state-tree';
import type { ThreeDSecureParametersBase, VerifyCardResult } from 'yl-braintree-three-ds/Types';

import { getRootStore } from '../../../../../root/RootStoreUtils';
import { formatAmount } from '../../../../../../reference/Currency';
import {
  EnrollmentPaymentType,
  PaymentMethodProviderType
} from '../../../../../../external/shared/api/EnrollmentClient.generated';
import { enrollmentResource } from '../../../../../../infrastructure/http/EnrollmentResource';
import { apiClient } from '../../../../model/credit-card/CreditCard';

async function initialize(currencyCode: string) {
  // eslint-disable-next-line import/no-unresolved
  const { InitializeThreeDSecure } = await import('yl-braintree-three-ds/YlBraintree3ds');
  await InitializeThreeDSecure(currencyCode);
}

export const Braintree3dsStore = types
  .model({})
  .volatile(() => ({
    initPromise: undefined as Promise<void> | undefined
  }))
  .views(self => ({
    get isEnabled(): boolean {
      const rootStore = getRootStore(self);
      const { appliedPaymentsReview, billingEntry, threeDSecureEntry } = rootStore.moduleStores;
      const { addCard3dsEnabled, braintree3dsEnabled, multiple3dsEnabled } = threeDSecureEntry!.config;

      const {
        paymentMethodProviderType = billingEntry?.creditCardData.paymentMethodProviderType,
        paymentType = billingEntry?.selectedPaymentType
      } = appliedPaymentsReview ?? {};

      const stepMatches = addCard3dsEnabled || rootStore.stepsManager.isLastStep;

      const gatewayMatches = !multiple3dsEnabled || paymentMethodProviderType === PaymentMethodProviderType.Braintree;

      return braintree3dsEnabled && stepMatches && gatewayMatches && paymentType === EnrollmentPaymentType.CreditCard;
    }
  }))
  .actions(self => ({
    async initializeAuthentication(): Promise<void> {
      if (!self.initPromise) {
        const rootStore = getRootStore(self);
        const currencyCode = rootStore.reference.currencies[0].code;
        self.initPromise = initialize(currencyCode);
      }
      return self.initPromise;
    },
    async getOrderExternalReferenceId(): Promise<string> {
      const result = await enrollmentResource.get(`order-information`);
      const data = result.data;
      return data.externalReferenceId;
    }
  }))
  .actions(self => ({
    authenticate: flow(function* authenticate(): any {
      yield self.initializeAuthentication();

      const rootStore = getRootStore(self);
      const { billingEntry, appliedPaymentsReview, shippingAndBillingReview, personalInfoReview, orderSummary, shippingAddressEntry } =
        rootStore.moduleStores;

      const currencyCode = rootStore.reference.currencies[0].code;
      const { enrollmentId } = rootStore.enrollmentStatus;
     
      const creditCardData = billingEntry?.creditCardData ?? appliedPaymentsReview!.creditCardData;

      const billingAddress = billingEntry?.addressForBilling ?? shippingAddressEntry?.primaryAddress ?? shippingAndBillingReview!.billingAddress;
      const shippingAddress = billingEntry?.userShippingAddress ?? shippingAddressEntry?.pickupPointAddress ?? shippingAndBillingReview!.userShippingAddress;
      const email = billingEntry?.userEmail ?? personalInfoReview!.email;
      const mobilePhoneNumber = billingEntry?.userPhoneNumber ?? personalInfoReview!.primaryPhone;

      const { encryptedCardNumber, encryptedCvv } = billingEntry?.creditCardData.encryptedParameters ?? {};

      const {
        TokenProviders,
        TransactionStatus,
        VerifyCard,
        VerifyCardNewCard
      }: typeof import('yl-braintree-three-ds/YlBraintree3ds') =
        // eslint-disable-next-line import/no-unresolved
        yield import('yl-braintree-three-ds/YlBraintree3ds');

      const threeDSecureParameters: ThreeDSecureParametersBase = {
        walletId: enrollmentId,
        currencyCode,
        bin: creditCardData.bin!,
        email,
        mobilePhoneNumber,
        billingAddress: {
          givenName: billingAddress.recipient?.split(' ')[0],
          surname: billingAddress.recipient?.split(' ')[1],
          phoneNumber: billingAddress.phoneNumber,
          streetAddress: billingAddress.street1,
          extendedAddress: billingAddress.street2,
          line3: billingAddress.street3,
          locality: billingAddress.city,
          region: billingAddress.stateProv,
          postalCode: billingAddress.postalCode,
          countryCodeAlpha2: billingAddress.country
        },
        additionalInformation: {
          shippingGivenName: shippingAddress.recipient?.split(' ')[0],
          shippingSurname: shippingAddress.recipient?.split(' ')[1],
          shippingPhone: shippingAddress.phoneNumber,
          shippingAddress: {
            streetAddress: shippingAddress.street1!,
            extendedAddress: shippingAddress.street2!,
            line3: shippingAddress.street3!,
            locality: shippingAddress.city!,
            region: shippingAddress.stateProv!,
            postalCode: shippingAddress.postalCode!,
            countryCodeAlpha2: shippingAddress.country!
          }
        }
      };

      const result: VerifyCardResult = rootStore.stepsManager.isLastStep
        ? yield VerifyCard({
            ...threeDSecureParameters,
            creditCardData: {
              tokenInfo: {
                value: creditCardData.tokenExToken!,
                provider: TokenProviders.TokenEx
              },
              expMonth: creditCardData.expirationMonth!,
              expYear: creditCardData.expirationYear!
            },
            amount: formatAmount(appliedPaymentsReview!.amount, 2),
            paymentMethodId: appliedPaymentsReview!.selectedPaymentIdentifier!,
            externalReferenceId: yield self.getOrderExternalReferenceId(),
            isSubscription: !!orderSummary?.isErEnrollment
          })
        : yield VerifyCardNewCard({
            ...threeDSecureParameters,
            creditCardData: {
              encryptedCreditCardNumber: encryptedCardNumber!,
              expMonth: creditCardData.expirationMonth!,
              expYear: creditCardData.expirationYear!,
              encryptedCvv
            }
          });
      const status = result?.threeDSecureInfo?.status;
      if (status !== TransactionStatus.AuthenticateSuccessful) {
        throw new Error(status);
      }
      if (rootStore.stepsManager.isLastStep) {
        yield apiClient.saveClientJsonValue(result.clientJsonValue);
      }
      return result.clientJsonValue;
    })
  }));

export interface Braintree3dsStore extends Instance<typeof Braintree3dsStore> {}
