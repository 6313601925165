import { flow, Instance, SnapshotIn, types } from 'mobx-state-tree';

import { enrollmentResource } from '../../infrastructure/http/EnrollmentResource';
import { ProductKit } from '../order-selection/starter-kit-selection/model/ProductKit';
import { ProductInfoStore } from '../product-info/model/ProductInfoStore';
import { getRootStore } from '../root/RootStoreUtils';

export const Currency = types.model('Currency', {
  code: types.string,
  symbol: types.string
});

export const EnrollmentOrderItem = types.model('EnrollmentOrderItem', {
  currency: Currency,
  description: types.maybe(types.string),
  howToUse: types.maybe(types.string),
  imageUrl: types.maybe(types.string),
  inStock: types.boolean,
  isPromotional: types.boolean,
  largeImageUrl: types.maybe(types.string),
  legacyId: types.number,
  name: types.maybe(types.string),
  partNumber: types.maybe(types.string),
  pointValue: types.number,
  priceWithTax: types.number,
  shortDescription: types.maybe(types.string),
  storeId: types.number,
  quantity: types.number,
  priceWithTaxTotal: types.number
});

export type EnrollmentOrderItem = Instance<typeof EnrollmentOrderItem>;

export const EnrollmentOrderInfoStore = types
  .model('EnrollmentOrderInfoStore', {
    starterKit: types.maybe(types.late(() => ProductKit)),
    additionalItems: types.optional(types.array(EnrollmentOrderItem), []),
    unavailableAdditionalItems: types.optional(types.array(EnrollmentOrderItem), []),
    erKit: types.maybe(types.late(() => ProductKit)),
    erAdditionalItems: types.optional(types.array(EnrollmentOrderItem), []),
    isEnrollingInEr: types.optional(types.boolean, false),
    usePremiumStarterKitAsErOrder: types.optional(types.boolean, false),
    usePremiumStarterKitAsMeoOrder: types.optional(types.boolean, false),
    showProductModal: types.optional(types.boolean, false),
    productInfo: types.maybe(ProductInfoStore),
    minimumErPv: types.number,
    starterKitPv: types.number,
    additionalItemsTotalPv: types.number,
    erAdditionalItemsTotalPv: types.number
  })
  .views(self => ({
    get currentPv(): number {
      return self.starterKitPv + self.additionalItemsTotalPv || 0;
    },
    get isMinimumErPvReached(): boolean {
      return this.currentPv >= self.minimumErPv;
    }
  }))
  .actions(self => {
    return {
      showProductInfo: flow(function* showProductInfo(kit: any): any {
        const { locale } = getRootStore(self).localeManager.language;
        const partNumber = kit.upgradeComponent ? kit.upgradeComponent.partNumber : kit.partNumber;
        const response = yield enrollmentResource.get<SnapshotIn<typeof ProductInfoStore>>(
          `/product-info/${locale}/${partNumber}`
        );
        self.productInfo = ProductInfoStore.create({
          kit: kit.itemId,
          ...response.data
        });
        self.showProductModal = true;
      }),
      hideProductInfo: () => {
        self.productInfo = undefined;
        self.showProductModal = false;
      }
    };
  });

export type EnrollmentOrderInfoStore = Instance<typeof EnrollmentOrderInfoStore>;
