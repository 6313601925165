import { Instance, types } from 'mobx-state-tree';

export const BusinessAddress = types.model('BusinessAddress', {
  country: types.maybe(types.string),
  city: types.maybe(types.string),
  postalCode: types.maybe(types.string),
  stateProv: types.maybe(types.string),
  street1: types.maybe(types.string),
  street2: types.maybe(types.string),
  street3: types.maybe(types.string),
  houseNumber: types.maybe(types.string),
  interiorNumber: types.maybe(types.string),
  neighborhood: types.maybe(types.string),
  isValidated: types.maybe(types.boolean)
});

export type BusinessAddress = Instance<typeof BusinessAddress>;
