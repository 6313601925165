import { Instance, types, SnapshotIn, applySnapshot, flow } from 'mobx-state-tree';
import { enrollmentResource } from '../../../../infrastructure/http/EnrollmentResource';
import { ModulesStore } from '../../module-management/model/ModuleStore';
import { EnrollmentModule, EnrollmentModules, LayoutModuleGroup } from './LayoutModuleGroup';
import { getRootStore } from '../../../root/RootStoreUtils';
import { EnrollmentRootStore } from '../../../root/EnrollmentRootStore';
import { StepLayout } from '../../../../external/shared/api/EnrollmentClient.generated';

export const StepNavigation = types.model('StepNavigation', {
  nextLabel: 'Continue',
  previousLabel: 'PreviousPage',
  justify: 'between',
  nextStepModule: types.maybe(types.enumeration<EnrollmentModule>('EnrollmentModules', EnrollmentModules)),
  previousStepModule: types.maybe(types.enumeration<EnrollmentModule>('EnrollmentModules', EnrollmentModules)),
  hideNavigation: types.optional(types.boolean, false)
});

export const EnrollmentStep = types
  .model('EnrollmentStep', {
    id: types.identifierNumber,
    layout: types.enumeration<StepLayout>('StepLayout', Object.values(StepLayout)),
    layoutModuleGroups: types.late(() => types.array(LayoutModuleGroup)),
    label: types.optional(types.string, ''),
    navigation: types.optional(StepNavigation, {})
  })
  .views(self => ({
    get rootStore(): EnrollmentRootStore {
      return getRootStore(self);
    },
    get allModules(): EnrollmentModule[] {
      const modulesArray = self.layoutModuleGroups.map(x => x.modules);
      return ([] as EnrollmentModule[]).concat(...modulesArray);
    },
    get moduleStore(): ModulesStore {
      return this.rootStore.moduleStores!;
    },
    get allModulesCompleted(): boolean {
      const completedModules = this.rootStore.moduleStores.completedModules;
      return this.allModules.every(moduleItem => completedModules.includes(moduleItem));
    }
  }))
  .views(self => ({
    get maxAvailableModuleGroup(): number {
      let maxIndex = 0;
      while (maxIndex < self.layoutModuleGroups.length) {
        maxIndex++;
        if (!self.layoutModuleGroups[maxIndex - 1].isCompleted) {
          return maxIndex;
        }
      }
      return maxIndex;
    }
  }))
  .actions(self => ({
    //  added for Er Agreement popup.
    ensureErAgreementsHandled: flow(function* ensureErAgreementsHandled(): any {
      const { erKitSelection } = self.rootStore.moduleStores;

      if (
        !erKitSelection?.enrollEssentialRewards ||
        !erKitSelection?.isEnableForErAgreement ||
        erKitSelection?.erAgreementAccepted ||
        erKitSelection?.isErAgreementDismissed
      ) {
        return false;
      }

      yield erKitSelection?.updateShowErAgreementModel(true);
      return true;
    })
  }))
  .actions(self => ({
    handleEnrollmentSwitch: flow(function* handleEnrollmentSwitch(modules: EnrollmentModule[]): any {
      if (self.rootStore.moduleStores.customerTypeEntry?.IsSwitched) {
        yield self.rootStore.switchEnrollment(self.rootStore.enrollmentStatus.enrollmentId);
      }
    })
  }))
  .actions(self => ({
    fetchViewModel: flow(function* fetchViewModel(module?: number): any {
      const params = module ? { module } : undefined;
      const response = yield enrollmentResource.get<SnapshotIn<typeof ModulesStore>>(`/steps/${self.id}`, { params });
      applySnapshot(self.rootStore.moduleStores, response.data);
    }),

    saveModuleStates: flow(function* saveModuleStates(): any {
      const rootStore = self.rootStore;
      yield rootStore.moduleStores.validateForm();

      if (yield self.ensureErAgreementsHandled()) {
        return false;
      }

      if (!rootStore.moduleStores.socialLinkCreatorEntry) {
        if (!rootStore.moduleStores.canProceed) {
          return false;
        }

        const prepareSuccess = yield rootStore.moduleStores.prepareResults();
        if (!prepareSuccess || !rootStore.moduleStores.canProceed) {
          return false;
        }
      }

      try {
        const formData = rootStore.moduleStores.getDataToSubmit();
        const response = yield enrollmentResource.post('/steps/save', formData);
        yield self.handleEnrollmentSwitch(response.data);
        const isHandlingSave = yield rootStore.moduleStores.handleSaveResponse();
        if (isHandlingSave) rootStore.moduleStores.addCompletedModules(response.data);
        return isHandlingSave;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        if (rootStore.moduleStores.billingEntry) {
          rootStore.moduleStores.billingEntry.showErrorModal(true);
        }
        return false;
      }
    }),

    ensureAgreementsHandled: flow(function* ensureAgreementsHandled() {
      const completeEnrollmentStore = self.rootStore.moduleStores.completeEnrollmentProcessor!;
      const { exceliaAgreementEntry, privacyPolicyAgreementEntry } = self.rootStore.moduleStores!;
      if (exceliaAgreementEntry! && !exceliaAgreementEntry.agreementAccepted) {
        if (exceliaAgreementEntry.document.length <= 0) {
          const result = yield enrollmentResource.post('generate-excelia-agreement', {
            commercialAgreementAccepted: false
          });
          exceliaAgreementEntry.updateModel(result.data, false);
        }
        completeEnrollmentStore.activateAgreement();
        return true;
      }
      if (privacyPolicyAgreementEntry! && !privacyPolicyAgreementEntry.agreementAccepted) {
        completeEnrollmentStore.activateAgreement();
        return true;
      }
      return false;
    })
  }))
  .actions(self => ({
    changeActiveModule: flow(function* changeActiveModule(moduleIndex: number): any {
      if (moduleIndex > self.maxAvailableModuleGroup) {
        return;
      }
      let canProceed = true;
      if (moduleIndex > self.moduleStore.activeModuleGroup!) {
        canProceed = yield self.saveModuleStates();
      }
      if (canProceed) {
        self.moduleStore.switchActiveGroup(moduleIndex);
      }
    })
  }))
  .actions(self => ({
    activateNextModuleGroup: flow(function* activateNextModuleGroup(ref?: any): any {
      try {
        self.rootStore.spin();
        if (self.moduleStore.activeModuleGroup! < self.layoutModuleGroups.length - 1) {
          yield self.changeActiveModule(self.moduleStore.activeModuleGroup! + 1);
        } else {
          const stepManager = self.rootStore.stepsManager;
          if (self.rootStore.moduleStores.earlyEnrollment) {
            if (yield self.ensureAgreementsHandled()) {
              return;
            }
            yield self.changeActiveModule(self.moduleStore.activeModuleGroup! + 1);
            self.moduleStore.setActiveModuleAsUndefined();
          } else {
            yield stepManager.nextStep();
          }
        }
      } finally {
        self.rootStore.unspin();
        if (ref) window.scrollTo(0, ref.current.offsetTop - 150);
      }
    }) as (ref?: any) => Promise<boolean>
  }))
  .named('EnrollmentStep');

export type EnrollmentStep = Instance<typeof EnrollmentStep>;
