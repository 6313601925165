import { Instance, types } from 'mobx-state-tree';
import { FieldState, FormState } from 'formstate';
import { required } from '../shared/validators/RequiredValidator';
import { SponsorEnrollerValidator } from './validators/SponsorEnrollerValidator';
import { validateIf } from '../shared/validators/ValidIf';
import { UplineValidationStatus } from '../../../external/shared/api/EnrollmentClient.generated';
import { GaiyoshomenValidator } from './validators/GaiyoshomenValidator';

export const SponsorEnrollerEntryStore = types
  .model({
    sponsorName: types.maybe(types.string),
    sponsorFirstName: types.maybe(types.string),
    sponsorLastName: types.maybe(types.string),
    hideSponsorLastName: types.optional(types.boolean, false),
    enrollerName: types.maybe(types.string),
    enrollerFirstName: types.maybe(types.string),
    enrollerLastName: types.maybe(types.string),
    hideEnrollerLastName: types.optional(types.boolean, false),
    sponsorId: types.maybe(types.number),
    enrollerId: types.maybe(types.number),
    wasReferred: types.maybe(types.boolean),
    sponsorEnrollerRequired: types.boolean,
    requirePlacementProgramConsent: types.boolean,
    includeGaiyoshomen: types.maybe(types.boolean),
    gaiyoshomenCode: types.maybe(types.string),
    excludeSponsor: types.optional(types.boolean, false),
    consentedToPlacementProgram: types.optional(types.boolean, false),
    validated: types.optional(types.boolean, false)
  })
  .actions(self => ({
    updateSponsorEnrollerName(validationResults: UplineValidationStatus) {
      self.sponsorName = validationResults.sponsorName;
      self.sponsorFirstName = validationResults.sponsorFirstName;
      self.sponsorLastName = validationResults.sponsorLastName;
      self.enrollerName = validationResults.enrollerName;
      self.enrollerFirstName = validationResults.enrollerFirstName;
      self.enrollerLastName = validationResults.enrollerLastName;
    },
    updateStoreFromForm(form: any) {
      self.sponsorId = form.sponsorId;
      self.enrollerId = form.enrollerId;
      self.gaiyoshomenCode = form.gaiyoshomenCode;
    }
  }))
  .views(self => ({
    get enrollerFullName() {
      return self.hideEnrollerLastName && self.enrollerLastName && self.enrollerLastName.length > 0
        ? `${self.enrollerFirstName} ${self.enrollerLastName.substring(0, 1).toUpperCase()}`
        : self.enrollerName!;
    },
    get sponsorFullName() {
      const hideLastName =
        self.hideSponsorLastName || (self.sponsorId === self.enrollerId && self.hideEnrollerLastName);

      return hideLastName && self.sponsorLastName && self.sponsorLastName.length > 0
        ? `${self.sponsorFirstName} ${self.sponsorLastName.substring(0, 1).toUpperCase()}`
        : self.sponsorName!;
    },
    get supportsDisplay() {
      return true;
    },
    get reactForm() {
      const sponsor = self.excludeSponsor
        ? undefined
        : new FieldState(self.sponsorId?.toString() || '').validators(required()).disableAutoValidation();
      const enroller = new FieldState(self.enrollerId?.toString() || '').validators(required()).disableAutoValidation();

      const sponsorEnrollerValidator = new SponsorEnrollerValidator(sponsor ?? enroller, enroller, result =>
        self.updateSponsorEnrollerName(result)
      );

      return new FormState<{ sponsor?: FieldState<string>; enroller: FieldState<string> }>(
        sponsor ? { sponsor, enroller } : { enroller }
      )
        .compose()
        .validators(() => sponsorEnrollerValidator.validSponsorEnroller());
    },
    get gaiyoshomenReactForm() {
      const gaiyoshomenCode = new FieldState(self.gaiyoshomenCode?.toString() || '')
        .validators(required())
        .disableAutoValidation();
      const gaiyoshomenValidator = new GaiyoshomenValidator(gaiyoshomenCode);
      return new FormState({ gaiyoshomenCode }).validators(() => gaiyoshomenValidator.validGaiyoshomen());
    },

    get placementForm() {
      return new FormState({
        consent: new FieldState(self.consentedToPlacementProgram)
          .validators(validateIf(x => !self.wasReferred && self.requirePlacementProgramConsent, required()))
          .disableAutoValidation()
      });
    },
    get sponsorSameAsEnroller(): boolean {
      return self.sponsorId === self.enrollerId;
    },
    get showRequiredError() {
      return self.validated && self.wasReferred === undefined;
    },
    getDataToSubmit(): any {
      const form = this.reactForm;
      const gaiyoForm = this.gaiyoshomenReactForm;
      const formData = {
        sponsorId: self.excludeSponsor ? Number(form.$.enroller.value) : Number(form.$.sponsor!.value),
        enrollerId: Number(form.$.enroller.value),
        wasReferred: self.wasReferred,
        consentedToPlacementProgram: self.consentedToPlacementProgram,
        gaiyoshomenCode: String()
      };
      if (self.includeGaiyoshomen) {
        const gaiyoshomenform = this.gaiyoshomenReactForm;
        formData.gaiyoshomenCode = String(gaiyoshomenform.$.gaiyoshomenCode.value);
      }
      self.updateStoreFromForm(formData);
      return formData;
    },
    get isPreventedFromSigningUp() {
      return self.sponsorEnrollerRequired && self.wasReferred === false;
    },
    canProceed() {
      if (this.isPreventedFromSigningUp || self.wasReferred === undefined) {
        return false;
      }

      if (self.wasReferred) {
        if (self.includeGaiyoshomen) {
          return !this.reactForm.hasError && !this.gaiyoshomenReactForm.hasError;
        } else {
          if (self.excludeSponsor) {
            return !this.reactForm.$.enroller.hasError;
          }
          return !this.reactForm.hasError;
        }
      }

      return !this.placementForm.hasError;
    },
    get showPlacementConsent() {
      return self.requirePlacementProgramConsent && self.wasReferred === false;
    }
  }))
  .actions(self => ({
    async validateForm() {
      self.validated = true;
      if (self.wasReferred) {
        return (
          (await self.reactForm.validate()) &&
          (self.includeGaiyoshomen ? await self.gaiyoshomenReactForm.validate() : true)
        );
      }

      if (self.requirePlacementProgramConsent && !self.wasReferred) {
        return self.placementForm.validate();
      }

      return Promise.resolve({ hasError: false });
    },
    setWasReferred(wasReferred: boolean) {
      self.wasReferred = wasReferred;
    },
    toggleConsentedToPlacement(v: boolean) {
      self.consentedToPlacementProgram = v;
    }
  }));

export type SponsorEnrollerEntryStore = Instance<typeof SponsorEnrollerEntryStore>;
