import { Instance,types } from "mobx-state-tree";
import { getRootStore } from "../../root/RootStoreUtils";
import { AddressEntryFormData } from "../../address/main-address/AddressEntryFormData";
import { ShippingMethod } from "./ShippingMethodSelectionStore";
import _ from "lodash";

export const ShiptoShopStore = types
  .model('ShiptoShopStore', {
  })
  .views(self => ({
    getDataToSubmit(): any {
      return {};
    },
    closeOverlay() {
        const overlay = document.getElementById("overlay");
        if (overlay) {
            overlay.remove();
        }
    }
  }))
  .actions(self => ({
    buildQuery (_address: AddressEntryFormData, shipMethod: ShippingMethod, orderId: string,WEB_URL:string,countryIsoCode3:string):any {
        
        const root = getRootStore(self);
        let language=root.localeManager.language.locale;
        language = language.slice(0, -2) + language.slice(-2).toUpperCase();
    
        const typeMethod = _.includes(shipMethod.name.toLowerCase(), 'store') ? 'STORE' : 'LOCKER';
    
        return (`${WEB_URL}?language=${encodeURI(language)}&country=${encodeURI(countryIsoCode3)}&shipMethod=${encodeURI(typeMethod)}&orderId=${encodeURI(orderId)}`);   
  },

    async showPopup (address:AddressEntryFormData, shipMethod:ShippingMethod, orderId:string,shipToshopURL:string,countryIsoCode3:string) {
    
        const left = (window.screen.width - 490) / 2;
        const top = (window.screen.height - 500) / 4;
        const CONFIG = `location=yes,height=600,width=600,top=${top},left=${left},scrollbars=no,status=yes`
        let WEB_URL='';
       
                let promise: Promise<object>;
        try {
                
                WEB_URL =  shipToshopURL;
                const urlQuery:any = this.buildQuery(address, shipMethod, orderId,WEB_URL,countryIsoCode3);
                window.open(urlQuery,'_blank', CONFIG);
                window.onabort= () => { self.closeOverlay() };
                window.onbeforeunload = () => { self.closeOverlay() };
                promise = new Promise((resolve, reject) => {
                const origin = WEB_URL;
              
                    function locationHandler(message:any) {
       
                        if (message.data && message.origin === origin) {
                            console.log(message + origin + message.data)
                            window.document.getElementById("overlay")!.remove();
                            window.removeEventListener('message', locationHandler)
                            resolve(message)
                        }
                        if (!message.data && message.origin === origin) {
                            console.log(message + origin + message.data)
                            window.document.getElementById("overlay")!.remove();
                            window.removeEventListener('message', locationHandler)
                            reject(message)
                        } 
                    }
       
                    window.addEventListener('message', locationHandler)
       
                    const overlay = window.document.createElement('div');
                    overlay.id = "overlay"
                    document.body.appendChild(overlay);
                })
               
            return promise;
            
        } catch (ex) {
            self.closeOverlay();
         
        }
      },
  }));

export type ShiptoShopStore = Instance<typeof ShiptoShopStore>;



