import { applySnapshot, Instance, types } from 'mobx-state-tree';
import { verifyTaxId } from '../../validator/TaxIdValidator';
import { BusinessAddress } from '../eu/BusinessAddress';
import { TaxIdVerificationResponse } from '../../validator/models/TaxIdVerificationResponse';
import { BusinessTaxEntry } from '../business/BusinessTaxEntry';

export const BusinessEUTax = types
  .model('BusinessEUTax', {
    businessAddress: types.maybe(BusinessAddress),
    taxIdVerificationResponse: types.maybe(TaxIdVerificationResponse)
  })
  .views(self => ({
    get taxIdVerified(): boolean {
      return !!self.taxIdVerificationResponse && self.taxIdVerificationResponse.isValid;
    },
    get postalCodeVerified(): boolean {
      if (self.taxIdVerificationResponse && self.businessAddress && self.businessAddress.postalCode) {
        return validateAddressPart(
          self.taxIdVerificationResponse.address.toUpperCase(),
          self.businessAddress.postalCode.toUpperCase()
        );
      }
      return false;
    },
    get cityVerified(): boolean {
      if (self.taxIdVerificationResponse && self.businessAddress && self.businessAddress.city) {
        return validateAddressPart(
          self.taxIdVerificationResponse.address.toUpperCase(),
          self.businessAddress.city.toUpperCase()
        );
      }
      return false;
    },
    get streetVerified(): boolean {
      if (self.taxIdVerificationResponse && self.businessAddress && self.businessAddress.street1) {
        const address = self.businessAddress;
        const street = `${address.street1 || ''} ${address.street2 || ''} ${address.street3 || ''}`;
        return validateAddressPart(self.taxIdVerificationResponse.address.toUpperCase(), street.toUpperCase());
      }
      return false;
    },
    get fullAddressVerified(): boolean {
      return this.postalCodeVerified && this.cityVerified && this.streetVerified;
    }
  }))
  .actions(self => ({
    setAdditionalValidationRules(business: BusinessTaxEntry) {
      const reactForm = business.reactForm;

      reactForm.validators(() => (!self.fullAddressVerified ? 'SomethingdNotVerified' : false));

      const taxIdValidators = [...business.taxIdValidators, (value: string) => this.runTaxIdVerification(value)];
      reactForm.$.taxId.validators(...taxIdValidators);
      if (business.showBusinessName) {
        reactForm.$.businessName.validators((value: string) => this.runBusinessNameVerification(value));
      }
    },

    runTaxIdVerification(taxId: string): Promise<string | null | false> {
      if (!taxId) return Promise.resolve('RequiredError');
      return verifyTaxId(taxId, this.setTaxIdVerificationResponse);
    },
    runBusinessNameVerification(businessName: string): string | null | false {
      if (!businessName) return 'RequiredError';
      if (self.taxIdVerificationResponse && self.taxIdVerificationResponse.isValid) {
        return validateBusinessName(self.taxIdVerificationResponse.name.toUpperCase(), businessName.toUpperCase())
          ? false
          : 'taxBusinessNameNotMached';
      }
      return 'taxBusinessNameNotMachedDueToTaxId';
    },
    businessAdressChange(name: string, value: string) {
      if (self.businessAddress === undefined) {
        self.businessAddress = BusinessAddress.create();
      }

      if (
        name === 'street1' ||
        name === 'street2' ||
        name === 'street3' ||
        name === 'city' ||
        name === 'stateProv' ||
        name === 'postalCode'
      ) {
        self.businessAddress[name] = value;
      }
    },
    initBusinessAddress(initData: any) {
      if (self.businessAddress) {
        return;
      }

      if (self.businessAddress === undefined) {
        self.businessAddress = BusinessAddress.create();
      }

      applySnapshot(self.businessAddress, initData);
    },
    setTaxIdVerificationResponse(response?: TaxIdVerificationResponse) {
      self.taxIdVerificationResponse = response;
    }
  }));

function validateBusinessName(viesName: string, enteredName: string): boolean {
  let valid = true;
  const expectedNameParts = splitAndNormalize(viesName);
  const enteredNameParts = splitAndNormalize(enteredName);

  expectedNameParts.forEach(word => {
    if (enteredNameParts.indexOf(word) < 0) {
      valid = false;
    }
  });

  return valid;
}

function validateAddressPart(viesAddress: string, enteredAddress: string): boolean {
  let valid = true;
  const expectedAddressParts = splitAndNormalize(viesAddress);
  const enteredAddressParts = splitAndNormalize(enteredAddress);

  enteredAddressParts.forEach(word => {
    if (expectedAddressParts.indexOf(word) < 0) {
      valid = false;
    }
  });

  return valid;
}

function splitAndNormalize(text: string): string[] {
  if (!text) return [];
  const splitedName = text.split(/\s/g);
  return splitedName
    .map(word => normalize(word))
    .join(' ')
    .split(/\s/g)
    .filter(word => !!word);
}

function removeNonAscii(text: string): string {
  if (text === null || text === '') return text;
  return text.replace(/[^\x20-\x7E]/g, ' ');
}

function removeWhiteSpaces(text: string): string {
  if (text === null || text === '') return text;
  return text.replace(/\s/g, '');
}

function removePunctuations(text: string): string {
  if (text === null || text === '') return text;
  return text.replace('.', ' ').replace(',', ' ').replace(';', ' ').replace(':', ' ').replace('-', ' ');
}

function removeDiacritics(text: string): string {
  if (text === null || text === '' || typeof String.prototype.normalize !== 'function') return text;
  return text
    .normalize('NFD')
    .replace(/[^\w\s]/g, '')
    .normalize('NFC');
}

function normalize(text: string): string {
  if (text === null || text === '') return text;
  text = removeWhiteSpaces(text);
  text = removePunctuations(text);
  text = removeDiacritics(text);
  return removeNonAscii(text);
}

export type BusinessEUTax = Instance<typeof BusinessEUTax>;
