import { FieldState } from 'formstate';

export function fieldsMustMatch(field1: string, field2: string, messageKey?: string) {
  return ($: { [key: string]: FieldState<any> }) => {
    return $[field1].value !== $[field2].value && (messageKey || 'fieldMatch');
  };
}

export function matchesField(otherField: FieldState<any>, messageKey?: string) {
  return (value: string) => value !== otherField.value && (messageKey || 'fieldMatch');
}
