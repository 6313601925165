import { Instance, types } from 'mobx-state-tree';
import { FieldState, FormState, Validator } from 'formstate';
import { required } from '../shared/validators/RequiredValidator';

export const Genders = ['Male', 'Female'];

export const GenderEntryStore = types
  .model({
    selectedGender: types.optional(types.string, ''),
    required: types.optional(types.boolean, true)
  })
  .views(self => ({
    get reactForm() {
      const validators: Validator<any>[] = [];
      if (self.required) validators.push(required());
      return new FormState({
        gender: new FieldState(self.selectedGender).validators(...validators)
      });
    },
    getDataToSubmit() {
      const form = this.reactForm;
      return {
        gender: form.$.gender.value
      };
    },
    canProceed() {
      return !this.reactForm.hasError;
    }
  }))
  .actions(self => ({
    validateForm() {
      return self.reactForm.validate();
    },
    setGender(gender: any) {
      self.selectedGender = gender;
    }
  }));

export type GenderEntryStore = Instance<typeof GenderEntryStore>;
