import { Instance, types } from 'mobx-state-tree';

export const BaseKit = types
  .model({
    name: types.string,
    image: types.optional(types.string, ''),
    imageLarge: types.optional(types.string, ''),
    price: types.number
  })
  .views(self => ({}));
export type BaseKit = Instance<typeof BaseKit>;
