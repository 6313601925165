import axios, { CancelToken, CancelTokenSource } from 'axios';
import { makeAutoObservable, runInAction } from 'mobx';

import { TaskCompletionSource } from './TaskCompletionSource';

export class ObservableTask<T> {
  isRunning = false;
  error: any = undefined;

  private cancelTokenSource?: CancelTokenSource;
  private taskCompletionSource = new TaskCompletionSource<T>();

  constructor(
    private readonly taskFactory: (cancelToken: CancelToken) => Promise<T>,
    private readonly onCompleted?: (result: T) => void,
    private readonly onFailed?: (error: any) => void,
    private readonly onCancelled?: () => void
  ) {
    makeAutoObservable(this);
  }

  async runOrRestart() {
    this.cancelTokenSource?.cancel();

    if (!this.isRunning) {
      this.taskCompletionSource = new TaskCompletionSource<T>();
      this.isRunning = true;
      this.error = undefined;
    }

    this.cancelTokenSource = axios.CancelToken.source();
    this.startNew(this.cancelTokenSource.token);

    await this.taskCompletionSource.task;
  }

  async runOrWait() {
    if (!this.isRunning) {
      this.taskCompletionSource = new TaskCompletionSource<T>();
      this.isRunning = true;
      this.error = undefined;
      this.cancelTokenSource = axios.CancelToken.source();
      this.startNew(this.cancelTokenSource.token);
    }

    await this.taskCompletionSource.task;
  }

  cancel() {
    this.cancelTokenSource?.cancel();
  }

  private async startNew(cancelToken: CancelToken) {
    try {
      const result = await this.taskFactory(cancelToken);
      cancelToken.throwIfRequested();
      runInAction(() => {
        this.isRunning = false;
        this.onCompleted?.(result);
        this.taskCompletionSource.resolve(result);
      });
    } catch (error) {
      if (axios.isCancel(error)) {
        runInAction(() => {
          this.onCancelled?.();
        });
      } else {
        runInAction(() => {
          this.isRunning = false;
          this.error = error;
          this.onFailed?.(error);
          this.taskCompletionSource.reject(error);
        });
      }
    }
  }
}
