import { Instance, types } from 'mobx-state-tree';
import { FieldState, FormState, Validator } from 'formstate';
import { required } from '../shared/validators/RequiredValidator';

export const ConfirmMemberInfoEntryStore = types
  .model({
    confirmed: types.maybe(types.boolean)
  })
  .views(self => ({
    get reactForm() {
      return new FormState({
        confirmed: new FieldState(self.confirmed?.toString() || '').validators(required()).disableAutoValidation()
      });
    },
    getDataToSubmit() {
      const form = this.reactForm;
      return {
        isStudent: form.$.confirmed
      };
    },
    canProceed() {
      return !this.reactForm.hasError;
    }
  }))
  .actions(self => ({
    validateForm() {
      return self.reactForm.validate();
    },
    toggleValue(value: boolean | string) {
      self.confirmed = value === true ? true : undefined;
    }
  }));

export type ConfirmMemberInfoEntryStore = Instance<typeof ConfirmMemberInfoEntryStore>;
