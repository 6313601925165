import { computed } from 'mobx';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { storePromiseObservable } from './bootstrapStore';
import { useSuspended, withPromiseState } from './infrastructure/hooks/useSuspended';
import { ShowScreenSpinner } from './external/shared/spinner/ScreenSpinner';

const Enrollment = React.lazy(() => import('./app/root/Enrollment'));

const storePromiseWithState = computed(() => withPromiseState(storePromiseObservable.get()));

export default observer(function EnrollmentLazy() {
  const store = useSuspended(storePromiseWithState.get());
  return store ? <Enrollment store={store} /> : <ShowScreenSpinner />;
});
