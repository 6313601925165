import { flow, Instance, types } from 'mobx-state-tree';
import { v4 as uuidv4 } from 'uuid';

import { EnrollmentPaymentType, EnrollmentPaymentTypes } from './EnrollmentPaymentTypes';
import { CreditCard } from './credit-card/CreditCard';
import { Sepa } from './Sepa';
import { enrollmentResource } from '../../../infrastructure/http/EnrollmentResource';
import { AddressEntryFormData } from '../../address/main-address/AddressEntryFormData';
import { BillingAddressStore } from '../components/credit-card/billing-address/BillingAddressStore';
import { EnrollmentRootStore } from '../../root/EnrollmentRootStore';
import { getRootStore } from '../../root/RootStoreUtils';
import { InstallmentStore } from '../components/installment/InstallmentStore';
import { BillingEntryForm, CreditCardConfigViewModel, PaymentMethodProviderType } from '../../../external/shared/api/EnrollmentClient.generated';

declare global {
  interface Window {
    MP_DEVICE_SESSION_ID: any;
  }
}

export const BillingEntryStore = types
  .model({
    selectedPaymentType: types.enumeration('EnrollmentPaymentType', EnrollmentPaymentTypes),
    creditCardData: CreditCard,
    creditCardConfig: types.frozen<CreditCardConfigViewModel>(),
    sepaData: Sepa,
    userEmail: types.maybe(types.string),
    userPhoneNumber: types.maybe(types.string),
    userShippingAddress: types.maybe(AddressEntryFormData),
    billingAddressEntry: types.maybe(BillingAddressStore),
    payPalEnvironment: types.string,
    availablePaymentMethods: types.array(types.enumeration('EnrollmentPaymentType', EnrollmentPaymentTypes)),
    showModal: false,
    installmentEntry: InstallmentStore,
    shouldCollectBillingAddress: types.optional(types.boolean, false),
    requestId: uuidv4()
  })
  .views(self => ({
    get rootStore(): EnrollmentRootStore {
      return getRootStore(self);
    },
    get addressForBilling(): AddressEntryFormData | undefined {
      if (self.billingAddressEntry?.sameAsShippingAddress === false) {
        return self.billingAddressEntry.billingAddress;
      }
      return self.userShippingAddress;
    },
    get supportsDisplay(): boolean {
      return true;
    },
    validateForm() {
      if (self.selectedPaymentType === EnrollmentPaymentType.CreditCard && !self.creditCardData.displayOnly) {
        return self.creditCardData.reactForm.validate();
      }
    },
    canProceed() {
      if (!self.installmentEntry.canProceed()) {
        return false;
      }

      if (!self.availablePaymentMethods.includes(self.selectedPaymentType)) {
        return false;
      }

      switch (self.selectedPaymentType) {
        case EnrollmentPaymentType.CreditCard:
        case EnrollmentPaymentType.Sepa:
          if (self.shouldCollectBillingAddress && !self.billingAddressEntry!.canProceed()) {
            return false;
          }
          break;
        default:
          break;
      }

      switch (self.selectedPaymentType) {
        case EnrollmentPaymentType.CreditCard:
          return self.creditCardData.canProceed();
        case EnrollmentPaymentType.Sepa:
          return self.sepaData.canProceed();
        default:
          return true;
      }
    },
    getDataToSubmit() {
      return {
        paymentType: self.selectedPaymentType,
        installment: self.installmentEntry.selectedInstallment,
        creditCard:
          self.selectedPaymentType === EnrollmentPaymentType.CreditCard && !self.creditCardData.displayOnly
            ? self.creditCardData.getDataToSubmit()
            : undefined,
        sepa: self.selectedPaymentType === EnrollmentPaymentType.Sepa ? self.sepaData.getDataToSubmit() : undefined,
        billingAddress: self.billingAddressEntry?.getDataToSubmit(),
        deviceData:
          self.creditCardData.paymentMethodProviderType === PaymentMethodProviderType.Mercadopago
            ? window.MP_DEVICE_SESSION_ID
            : undefined,
        requestId: self.requestId
      } as BillingEntryForm;
    },
    get pickablePayments() {
      if (
        self.selectedPaymentType === EnrollmentPaymentType.PayPal ||
        self.selectedPaymentType === EnrollmentPaymentType.VirtualWalletPaypal
      ) {
        return self.availablePaymentMethods;
      }
      return self.availablePaymentMethods.filter(
        x => x !== EnrollmentPaymentType.PayPal && x !== EnrollmentPaymentType.VirtualWalletPaypal
      );
    },
    get shouldShowPayPalButton() {
      return (
        self.availablePaymentMethods.includes(EnrollmentPaymentType.PayPal) &&
        self.selectedPaymentType !== EnrollmentPaymentType.PayPal
      );
    },
    get shouldShowVirtualWalletPayPalButton() {
      return (
        self.availablePaymentMethods.includes(EnrollmentPaymentType.VirtualWalletPaypal) &&
        self.selectedPaymentType !== EnrollmentPaymentType.VirtualWalletPaypal
      );
    },
    get showRadioSelections() {
      if (self.availablePaymentMethods.length > 1) {
        return true;
      }
      switch (self.availablePaymentMethods[0]) {
        case EnrollmentPaymentType.CreditCard:
        case EnrollmentPaymentType.Sepa:
          return false;
        default:
          return true;
      }
    }
  }))
  .actions(self => ({
    showErrorModal(value = true) {
      self.showModal = value;
      if (!value) {
        self.requestId = uuidv4();
      }
      return self.showModal;
    }
  }))
  .actions(self => {
    return {
      switchSelectedPaymentType(paymentType: EnrollmentPaymentType) {
        self.selectedPaymentType = paymentType;
        self.installmentEntry.reset();
      },
      prepareResults: flow(function* prepareResults(): any {
        const rootStore = self.rootStore;
        const { captchaEntry } = rootStore.moduleStores!;

        if (captchaEntry) {
          if (!captchaEntry.token && !captchaEntry.validateForm()) return false;
          const captchaResponse = yield enrollmentResource.post('/captcha/validate-token', {
            token: captchaEntry.token
          });
          if (!captchaResponse.data) return false;
        }

        if (self.selectedPaymentType === EnrollmentPaymentType.CreditCard) {
          if (self.creditCardData.displayOnly) {
            return true;
          }
          const validatedAddress = self.billingAddressEntry ? yield self.billingAddressEntry.prepareResults() : true;
          const validated = yield self.creditCardData.prepareResults();
          if (!validated || !validatedAddress) {
            return false;
          }

          try {
            yield self.creditCardData.validateCreditCardAsync();
            return true;
          } catch (error) {
            self.showErrorModal();
            console.error(error);
            return false;
          }
        }

        if (self.selectedPaymentType === EnrollmentPaymentType.Sepa) {
          if (self.sepaData.displayOnly) {
            return true;
          }
          const validated = yield self.sepaData.prepareResults();
          const validatedAddress = self.billingAddressEntry ? yield self.billingAddressEntry.prepareResults() : true;
          if (!validated || !validatedAddress) {
            return false;
          }

          return true;
        }

        return true;
      }),
      handleSaveResponse: flow(function* handleSaveResponse() {
        if (self.selectedPaymentType === EnrollmentPaymentType.CreditCard) {
          self.creditCardData.handleSaveResponse();
        }
        return yield self.installmentEntry.handleSaveResponse();
      }),
      continueNextStep() {
        if (self.installmentEntry.canProceed()) {
          self.rootStore.stepsManager.nextStep();
        }
      },
      editCreditCard() {
        self.creditCardData = CreditCard.create();
        self.installmentEntry.reset();
      },
      editSepa() {
        self.sepaData = Sepa.create();
      }
    };
  });

export type BillingEntryStore = Instance<typeof BillingEntryStore>;
