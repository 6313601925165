import { initChase3ds } from '@yl/chase-3ds';
import { ThreeDsRequest } from '@yl/chase-3ds/dist/ThreeDsRequest';
import { flow, Instance, types } from 'mobx-state-tree';
import { v4 as uuidv4 } from 'uuid';

import { getRootStore } from '../../../../../root/RootStoreUtils';
import {
  EnrollmentPaymentType,
  PaymentMethodProviderType
} from '../../../../../../external/shared/api/EnrollmentClient.generated';
import { apiClient } from '../../../../model/credit-card/CreditCard';

enum NextAction {
  NotSet = 'NotSet',
  DoNotProceed = 'DoNotProceed',
  ProceedWithSecureAuthentication = 'ProceedWithSecureAuthentication',
  ProceedWithoutSecureAuthentication = 'ProceedWithoutSecureAuthentication',
  RetryWithSecureAuthentication = 'RetryWithSecureAuthentication'
}

type Response = ReturnType<ReturnType<typeof initChase3ds>['api']['generateReturnResponses']>[number] & {
  nextAction: NextAction;
};

export const Chase3dsStore = types
  .model({
    display3dsDialog: types.optional(types.boolean, false)
  })
  .volatile(() => ({
    chase3ds: initChase3ds(),
    initPromise: undefined as Promise<Response[]> | undefined
  }))
  .views(self => ({
    get isEnabled(): boolean {
      const { appliedPaymentsReview, threeDSecureEntry } = getRootStore(self).moduleStores;
      const { paymentType, paymentMethodProviderType } = appliedPaymentsReview ?? {};
      return (
        threeDSecureEntry!.config.chase3dsEnabled &&
        paymentType === EnrollmentPaymentType.CreditCard &&
        (!paymentMethodProviderType || paymentMethodProviderType === PaymentMethodProviderType.Orbital)
      );
    }
  }))
  .actions(self => ({
    initializeAuthentication(): Promise<Response[]> {
      if (!self.initPromise) {
        const rootStore = getRootStore(self);

        const appliedPayments = rootStore.moduleStores.appliedPaymentsReview!;

        const request = ThreeDsRequest.create({
          amount: appliedPayments.amount,
          currency: rootStore.reference.currencies[0].code,
          orderId: rootStore.enrollmentStatus.enrollmentId,
          transactionId: uuidv4(),
          token: appliedPayments.creditCardData!.tokenExToken!,
          expireMonth: appliedPayments.creditCardData!.expirationMonth!,
          expireYear: appliedPayments.creditCardData!.expirationYear!
        });

        self.initPromise = new Promise<Response[]>(resolve => {
          self.chase3ds.initializeAuthentication([request], resolve);
        });
      }
      return self.initPromise;
    }
  }))
  .actions(self => ({
    authenticate: flow(function* authenticate(): any {
      const [initResponse] = (yield self.initializeAuthentication()) as Response[];
      self.initPromise = undefined;

      switch (initResponse.nextAction) {
        case NextAction.DoNotProceed:
        case NextAction.RetryWithSecureAuthentication:
        case NextAction.NotSet:
          throw new Error(initResponse.message);
        case NextAction.ProceedWithoutSecureAuthentication:
          return;
        default:
          break;
      }

      let authResponse: Response;

      self.display3dsDialog = true;
      try {
        [authResponse] = yield new Promise<Response[]>(resolve => self.chase3ds.authenticate(resolve));
      } finally {
        self.display3dsDialog = false;
      }

      switch (authResponse.nextAction) {
        case NextAction.ProceedWithSecureAuthentication:
          break;
        default:
          throw new Error(authResponse.message);
      }

      const {
        eci,
        skippedAuthForLowValue,
        authenticationToken,
        dsTransactionId,
        threeDsVersion,
        issuerSupports3ds,
        transactionId
      } = authResponse;

      const clientJsonValue = JSON.stringify({
        eci,
        skippedAuthForLowValue,
        authenticationToken,
        dsTransactionId,
        version: threeDsVersion,
        issuerSupports3ds,
        transactionId
      });
      yield apiClient.saveClientJsonValue(clientJsonValue);
    })
  }));

export type Chase3dsStore = Instance<typeof Chase3dsStore>;
