import { Instance, types } from 'mobx-state-tree';
import { AddressEntryFormData } from '../address/main-address/AddressEntryFormData';
import { ShippingMethod } from '../shipping/method-selection/ShippingMethodSelectionStore';
import { getRootStore } from '../root/RootStoreUtils';
import type { PickupPointModalResult, PickupPointModalOptions } from 'yl-pickup-point';

export type ShowPickupPointResult = AddressEntryFormData | undefined;

export const PickupPointModalStore = types
  .model({
    modalOptions: types.maybe(types.frozen<PickupPointModalOptions>())
  })
  .views(self => ({
    get availableShippingMethods(): ShippingMethod[] | undefined {
      return getRootStore(self).moduleStores?.shippingMethodSelection?.shippingMethods;
    },
    get locale(): string {
      return getRootStore(self).localeManager.language.locale;
    }
  }))
  .volatile(() => ({
    pickupPointModalDeferred: undefined as ((value: AddressEntryFormData | undefined) => void) | undefined
  }))
  .actions(self => ({
    showPickupPointModal(): Promise<ShowPickupPointResult> {
      const rootStore = getRootStore(self);
      const shippingAddressEntry = rootStore.moduleStores.shippingAddressEntry;
      const phoneValidation = shippingAddressEntry?.phoneValidation;
      const sourceAddress = shippingAddressEntry?.pickupPointAddress || shippingAddressEntry?.userShippingAddress;

      self.modalOptions = {
        locale: self.locale,
        shippingMethod: rootStore.moduleStores.shippingMethodSelection!.selectedShippingMethod!,
        phoneOptions:
          !phoneValidation || phoneValidation.internationalMode
            ? {
                internationalMode: true,
                defaultCountryIsoCode: rootStore.enrollmentStatus.countryIso
              }
            : {
                internationalMode: false,
                validationPattern: phoneValidation.phoneRegularExpression
              },
        shippingAddress: sourceAddress && {
          houseNumber: sourceAddress.houseNumber,
          interiorNumber: sourceAddress.interiorNumber,
          neighborhood: sourceAddress.neighborhood,
          countryIsoCode: sourceAddress.country || '',
          city: sourceAddress.city || '',
          stateProv: sourceAddress.stateProv,
          postalCode: sourceAddress.postalCode || '',
          street1: sourceAddress.street1 || '',
          street2: sourceAddress.street2 || '',
          street3: sourceAddress.street3 || '',
          street4: '',
          phoneNumber: sourceAddress.phoneNumber,
          recipient: sourceAddress.recipient
        }
      };

      return new Promise<AddressEntryFormData | undefined>(resolve => {
        self.pickupPointModalDeferred = resolve;
      });
    },
    closePickupPointModal(result: PickupPointModalResult | undefined): void {
      self.modalOptions = undefined;
      if (self.pickupPointModalDeferred) {
        if (result) {
          const address = AddressEntryFormData.create({
            city: result.address.city,
            country: result.address.countryIsoCode2,
            postalCode: result.address.postalCode,
            stateProv: result.address.stateProv,
            street1: result.address.street1,
            street2: result.address.street2,
            street3: result.address.street3,
            phoneNumber: result.address.phoneNumber,
            recipient: result.address.recipient,
            isWillCall: false,
            isPickupPoint: true,
            isAusPost: false,
            isValidated: true
          });
          self.pickupPointModalDeferred(address);
        } else {
          self.pickupPointModalDeferred(undefined);
        }
      }
      self.pickupPointModalDeferred = undefined;
    }
  }));

export type PickupPointModalStore = Instance<typeof PickupPointModalStore>;
