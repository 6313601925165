import { flow, Instance, resolveIdentifier, SnapshotIn, types } from 'mobx-state-tree';

import { ProductKit } from './ProductKit';
import { StarterKitTab } from './StarterKitTab';
import { BaseKit } from './BaseKit';
import { ProductInfoStore } from '../../../product-info/model/ProductInfoStore';
import { enrollmentResource } from '../../../../infrastructure/http/EnrollmentResource';
import { getRootStore } from '../../../root/RootStoreUtils';
 

export const EnrollmentKitSelectionFormData = types.model('EnrollmentKitSelectionFormData', {
  selectedKitId: types.maybe(types.number)
});

export const EnrollmentKitSelectionStore = types
  .model('EnrollmentKitSelectionStore', {
    displayStyle: types.enumeration('DisplayStyle', ['Tabbed', 'Standard']),
    tabs: types.late(() => types.array(StarterKitTab)),
    currentTab: types.late(() => types.reference(StarterKitTab)),
    selectedKit: types.maybe(types.reference(types.late(() => ProductKit))),
    additionalOrderItems: types.array(types.number),
    baseKit: types.maybe(BaseKit),
    showProductModal: types.optional(types.boolean, false),
    productInfo: types.maybe(ProductInfoStore),
    requireStarterKit: types.boolean,
    minimumErPv: types.number,
    isMeoEnabled: types.boolean,
    minimumEnrollmentOrderPv: types.number,
    additionalOrderItemsTotalPv: types.number,
    minimumPVRequiredModal:false,
    setERTextModal:false
  })
  .views(self => ({
    getDataToSubmit(): any {
      if (self.requireStarterKit || self.selectedKit !== undefined) {
        return {
          selectedKitId: self.selectedKit!.itemId
        };
      }
      return {
        selectedKitId: null
      };
    },
    canProceed(): boolean {
      const rootStore = getRootStore(self);
      const kitStore = rootStore.moduleStores.enrollmentKitSelection!;
      const totalOrderPV = this.currentPv;
      const canProceed = 
          rootStore.moduleStores.allowCompletionWithoutOrder || 
          self.additionalOrderItems.length > 0  ||
          totalOrderPV >= kitStore.minimumErPv;
      return !!canProceed;
    },
    selectedKitOrProducts(): boolean {
      return self.selectedKit !== undefined || self.additionalOrderItems.length > 0;
    },
    get selectedKitTab(): any {
      if (!self.selectedKit) {
        return undefined;
      }
      return self.tabs.find(tab => tab.productKits.includes(self.selectedKit as any));
    },
    get pskAsFirstErEligibleKitSelected(): boolean {
      return self.selectedKit !== undefined && self.selectedKit.pointValue >= self.minimumErPv;
    },
    get currentPv(): number {
      return (self.additionalOrderItemsTotalPv || 0) + (self.selectedKit ? self.selectedKit.pointValue : 0);
    },
    get isMinimumErPvReached(): boolean {
      return this.currentPv >= self.minimumErPv;
    }
  }))
  .actions(self => {
    return {
      switchTab(tab: number): void {
        self.currentTab = resolveIdentifier<typeof StarterKitTab>(StarterKitTab, self, tab)!;
      },
      choseKit(kit: ProductKit): void {
        if (!self.requireStarterKit && self.selectedKit === kit) {
          self.selectedKit = undefined;
        }
         else {
          self.selectedKit = kit;
        }
        this.hideProductInfo();
        this.refreshCart();
      },
      choseDefaultKitInTab(tab: StarterKitTab): void {
        if (self.selectedKitTab !== self.currentTab && self.requireStarterKit && !self.isMeoEnabled) {
          this.choseKit(tab.productKits[0]);
        }
      },
      showProductInfo: flow(function* showProductInfo(kit: ProductKit): any {
        const { locale } = getRootStore(self).localeManager.language;
        const partNumber = kit.upgradeComponent ? kit.upgradeComponent.partNumber : kit.partNumber;
        const response = yield enrollmentResource.get<SnapshotIn<typeof ProductInfoStore>>(
          `/product-info/${locale}/${partNumber}`
        );
        self.productInfo = ProductInfoStore.create({
          kit: kit.itemId,
          ...response.data
        });
        self.showProductModal = true;
      }),
      hideProductInfo(): void {
        self.productInfo = undefined;
        self.showProductModal = false;
      },
      updateRequireStarterKit(value: boolean) { 
        self.requireStarterKit = value;
      },
      refreshCart(): void {
        const rootStore = getRootStore(self);
        const meoKitSelection = rootStore.moduleStores.meoKitSelection;
        if (self.isMeoEnabled && meoKitSelection !== undefined) {
          meoKitSelection.refreshMeoCart(self.selectedKit);
        }
         self.minimumPVRequiredModal = !self.canProceed() && self.selectedKit != undefined; 
      },
      setMinimumPVRequiredModal(value: boolean) {
        self.minimumPVRequiredModal = value;
      },
      closeMinimumPVRequiredRequiredModal() {
          self.minimumPVRequiredModal = false;
      },
      setMinimumPVERTextModal(value: boolean) {
        self.setERTextModal = value;
      },
      closeERTextModal(){
        self.setERTextModal=false;
      }
    };
  })
  .actions(self => ({
    afterCreate(): void {
      if (!self.selectedKit && !self.isMeoEnabled) {
        self.choseDefaultKitInTab(self.currentTab);
      }
    }
  }));

export interface EnrollmentKitSelectionStore extends Instance<typeof EnrollmentKitSelectionStore> {}
