import { Instance, types } from 'mobx-state-tree';
import { FormState, FieldState } from 'formstate';
import { AgreementDocument } from '../agreement/model/AgreementDocument';
import { required } from '../shared/validators/RequiredValidator';

export const PrivacyPolicyAgreementEntryStore = types
  .model({
    text: types.maybe(types.string),
    scrollText: types.maybe(types.string),
    documents: types.array(AgreementDocument),
    agreementAccepted: types.optional(types.boolean, false)
  })
  .views(self => ({
    get reactForm() {
      return new FormState({
        agreementAccepted: new FieldState(self.agreementAccepted).disableAutoValidation().validators(required())
      });
    },
    canProceed() {
      return !this.reactForm.hasError;
    },
    getDataToSubmit() {
      return { agreementAccepted: this.reactForm.$.agreementAccepted.value };
    }
  }))
  .actions(self => ({
    validateForm() {
      return self.reactForm.validate();
    },
    setAgreementAccepted() {
      self.agreementAccepted = true;
    }
  }))
  .named('PrivacyPolicyAgreementEntryStore');

export type PrivacyPolicyAgreementEntryStore = Instance<typeof PrivacyPolicyAgreementEntryStore>;
