import { flow, getSnapshot, Instance, types } from 'mobx-state-tree';
import { FieldState, FormState } from 'formstate';
import { getFormValues } from '@yl/react-forms/dist';
import { EnrollmentModuleForm } from '../../enrollment-process/module-management/model/EnrollmentModuleForm';
import { NameEntryFormData } from './NameEntryFormData';
import { required } from '../shared/validators/RequiredValidator';
import { replaceUnallowedCharacters } from '../../../infrastructure/forms/InputTextFormatter';
import { ComponentField } from '../../../infrastructure/dynamic-forms/ComponentField';
import { enrollmentResource } from '../../../infrastructure/http/EnrollmentResource';

export const NameEntryStore = types
  .model({
    form: EnrollmentModuleForm,
    data: NameEntryFormData,
    useTranslation: types.optional(types.boolean, false)
  })
  .actions(self => ({
    updateStoreFromForm(form: any) {
      self.data.firstName = form.firstName ?? '';
      self.data.lastName = form.lastName ?? '';
      self.data.middleName = form.middleName ?? '';
      self.data.lastNameKana = form.lastNameKana ?? '';
      self.data.firstNameKana = form.firstNameKana ?? '';
      self.data.lastNameRomaji = form.lastNameRomaji ?? '';
      self.data.firstNameRomaji = form.firstNameRomaji ?? '';
    },
    async tryTransalateJpName(data: any, name: string, isLastName: boolean): Promise<void> {
      const response = await enrollmentResource.get<any>(`/kana-look-up/${name}`);

      if (!response.data) {
        return;
      }

      if (isLastName) {
        data.lastNameKana = name;
        data.lastNameRomaji = response.data;
      }
      else {
        data.firstNameKana = name;
        data.firstNameRomaji = response.data;
      }

      this.updateStoreFromForm(data);
    },
  }))
  .views(self => ({
    get supportsDisplay(): boolean {
      return true;
    },
    get reactForm(): FormState<any> {
      const forms = {} as any;
      const formValues = getSnapshot(self.data) as any;
      self.form.formFields.forEach(field => {
        const fieldState = new FieldState(formValues[field.name]);

        if (field.required) {
          fieldState.validators(required());
        }

        forms[field.name] = fieldState;
      });

      return new FormState(forms);
    },

    getDataToSubmit() {
      const form = getFormValues(this.reactForm);
      self.updateStoreFromForm(form);
      return form;
    },
    canProceed() {
      return !this.reactForm.hasError;
    }
  }))
  .actions(self => ({
    validateForm() {
      return self.reactForm.validate();
    },
    applyFormat: flow(function* debounceFormat(field: ComponentField) {
      yield new Promise(r => setTimeout(r, 1000));
      replaceUnallowedCharacters(self.reactForm.$[field.name], field.formatRegexForReplace);
    }),
    tryTransalate(field: ComponentField) {
      if (self.useTranslation &&
      (field.name === 'lastNameKana' || field.name === 'firstNameKana')) {
        const fieldValue = self.reactForm.$[field.name].value;

        if (fieldValue) {
          const isLastName = field.name === 'lastNameKana';

          self.tryTransalateJpName({ ...getFormValues(self.reactForm) }, fieldValue, isLastName);
        }
      }
    }
  }));

export type NameEntryStore = Instance<typeof NameEntryStore>;
