import { Instance, types } from 'mobx-state-tree';
import { ECommerceAnalyticsEventPartial } from '../../../infrastructure/analytics/gtm-support';
import { getDecimalPrice } from '../../../reference/Currency';

export const OrderTotalReviewStore = types
  .model('OrderTotalReviewStore', {
    subtotal: types.number,
    shipping: types.number,
    taxTotal: types.number,
    total: types.number,
    pvTotal: types.number,
    taxableAmount: types.number,
    salesTax: types.number,
    shippingTax: types.number,
    activeTaxTab: types.maybe(types.boolean)
  })
  .views(self => ({
    get eCommerceData(): ECommerceAnalyticsEventPartial {
      return {
        purchase: {
          actionField: {
            revenue: getDecimalPrice(self.total).toString(),
            tax: getDecimalPrice(self.taxTotal).toString(),
            shipping: getDecimalPrice(self.shipping).toString()
          }
        }
      };
    }
  }))
  .actions(self => ({
    switchTabTaxDefinition(value: boolean) {
      self.activeTaxTab = value;
    }
  }));

export type OrderTotalReviewStore = Instance<typeof OrderTotalReviewStore>;
