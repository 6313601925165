import { flow, getSnapshot, Instance, types } from 'mobx-state-tree';
import { FieldState, FormState } from 'formstate';
import { getFormValues } from '@yl/react-forms/dist';
import { EnrollmentModuleForm } from '../../enrollment-process/module-management/model/EnrollmentModuleForm';
import { required } from '../shared/validators/RequiredValidator';
import { replaceUnallowedCharacters } from '../../../infrastructure/forms/InputTextFormatter';
import { ComponentField } from '../../../infrastructure/dynamic-forms/ComponentField';

export const CoApplicantEntryFormData = types.model('CoApplicantEntryFormData', {
  hasCoApplicant: types.boolean,
  firstName: types.maybe(types.string),
  middleName: types.maybe(types.string),
  lastName: types.maybe(types.string)
});

export type CoApplicantEntryFormData = Instance<typeof CoApplicantEntryFormData>;
export const CoApplicantEntryStore = types
  .model({
    form: EnrollmentModuleForm,
    data: CoApplicantEntryFormData
  })
  .actions(self => ({
    updateStoreFromForm(form: any) {
      self.data.firstName = form.firstName;
      self.data.lastName = form.lastName;
      self.data.middleName = form.middleName;
      self.data.hasCoApplicant = form.hasCoApplicant;
    }
  }))
  .views(self => ({
    get supportsDisplay(): boolean {
      return true;
    },
    get reactForm() {
      const forms = {} as any;
      const formValues = getSnapshot(self.data) as any;

      self.form.formFields.forEach(field => {
        const fieldState = new FieldState(formValues[field.name]);
        forms[field.name] = fieldState;
        if (field.required) {
          fieldState.validators(required());
        }
      });
      return new FormState(forms);
    },
    getDataToSubmit() {
      const toSubmit = {
        hasCoApplicant: self.data.hasCoApplicant,
        ...getFormValues(this.reactForm)
      };
      self.updateStoreFromForm(toSubmit);
      return toSubmit;
    },
    canProceed() {
      if (!self.data.hasCoApplicant) {
        return true;
      }
      return !this.reactForm.hasError;
    }
  }))
  .actions(self => ({
    validateForm() {
      return self.reactForm.validate();
    },
    addCoApplicant() {
      self.data.hasCoApplicant = true;
    },
    removeCoApplicant() {
      self.data.hasCoApplicant = false;
    },
    applyFormat: flow(function* debounceFormat(field: ComponentField) {
      yield new Promise(r => setTimeout(r, 1000));
      replaceUnallowedCharacters(self.reactForm.$[field.name], field.formatRegexForReplace);
    })
  }));

export type CoApplicantEntryStore = Instance<typeof CoApplicantEntryStore>;
