import utf8 from 'utf8';

export function maxByteSize(size: number | undefined, messageKey?: string) {
  return (value: string) => {
    if (!size) {
      return false;
    }

    return utf8.encode(value).length > size && (messageKey || 'MaxlengthError');
  };
}
