import { Instance, types } from 'mobx-state-tree';
import { FieldState, FormState, Validator } from 'formstate';
import { required } from '../../../shared/validators/RequiredValidator';
import { replaceFieldWithDashes } from '../TaxIdFormatter';
import { matchesRegExp } from '../../../shared/validators/RegularExpressionValidator';
import { noDuplicateTaxIdValidator } from '../../validator/NoDuplicateTaxIdValidator';
import { BusinessEUTax } from '../eu/BusinessEUTax';

export type BusinessTaxEntryForm = FormState<{
  businessName: FieldState<string>;
  taxId: FieldState<string>;
  businessLicenseId: FieldState<string>;
}>;

export const BusinessTaxEntry = types
  .model('BusinessTaxEntry', {
    businessName: types.maybe(types.string),
    taxId: types.maybe(types.string),
    taxIdTypeId: types.frozen(types.number),
    isRequired: types.boolean,
    isUniqueRequired: types.optional(types.boolean, false),
    businessLicense: types.maybe(types.string),
    showBusinessName: types.boolean,
    requireBusinessName: types.boolean,
    showBusinessLicenseId: types.boolean,
    requireBusinessLicense: types.boolean,
    name: types.string,
    mask: types.string,
    regularExpression: types.string,
    canCollect: types.boolean,
    isGSTRegistered: types.boolean
  })
  .volatile(() => ({
    businessEUTax: BusinessEUTax.create()
  }))
  .views(self => ({
    get taxIdValidators() {
      const validators: Validator<any>[] = [];
      if (self.isRequired) {
        validators.push(required());
      }

      validators.push(matchesRegExp(self.regularExpression, 'invalidTaxId'));
      if (self.isUniqueRequired) {
        validators.push((value: string) => {
          return value !== self.taxId ? noDuplicateTaxIdValidator(value, self.taxIdTypeId) : Promise.resolve(false);
        });
      }
      return validators;
    },
    get reactForm(): BusinessTaxEntryForm {
      const form = new FormState({
        businessLicenseId: new FieldState(self.businessLicense || ''),
        businessName: new FieldState(self.businessName || ''),
        taxId: new FieldState(self.taxId || '').validators(...this.taxIdValidators)
      });

      if (self.requireBusinessLicense) {
        form.$.businessLicenseId.validators(required());
      }

      if (self.requireBusinessName) {
        form.$.businessName.validators(required());
      }

      return form;
    },

    get taxIdField(): FieldState<string> {
      return this.reactForm.$.taxId;
    },
    get requireTaxId(): boolean {
      return self.isRequired;
    }
  }))
  .actions(self => ({
    fixDashes() {
      replaceFieldWithDashes(self.reactForm.$.taxId, self.mask);
    },
    afterCreate() {
      this.fixDashes();
    }
  }));

export interface BusinessTaxEntry extends Instance<typeof BusinessTaxEntry> {}
