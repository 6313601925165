import { Instance, types } from 'mobx-state-tree';
import { Language } from './Language';

export const CountryLanguages = types.model('CountryLanguages', {
  isoCode2: types.string,
  isoCode3: types.string,
  name: types.string,
  languages: types.array(Language)
});
export type CountryLanguages = Instance<typeof CountryLanguages>;
