import { cybersource3ds } from '@yl/cybersource-3ds';
// eslint-disable-next-line import/no-unresolved
import { IOrderFor3DsCheck } from '@yl/cybersource-3ds/dist/model/Order';
import { flow, Instance, types } from 'mobx-state-tree';

import {
  EnrollmentPaymentType,
  PaymentMethodProviderType
} from '../../../../../../external/shared/api/EnrollmentClient.generated';
import { getRootStore } from '../../../../../root/RootStoreUtils';

export const Cybersource3dsStore = types
  .model({})
  .volatile(() => ({
    initPromise: undefined as Promise<void> | undefined
  }))
  .views(self => ({
    get isEnabled(): boolean {
      const { appliedPaymentsReview, threeDSecureEntry } = getRootStore(self).moduleStores;
      const { paymentType, paymentMethodProviderType } = appliedPaymentsReview ?? {};
      return (
        threeDSecureEntry!.config.cybersource3dsEnabled &&
        paymentType === EnrollmentPaymentType.CreditCard &&
        (!paymentMethodProviderType || paymentMethodProviderType === PaymentMethodProviderType.CyberSource)
      );
    }
  }))
  .actions(self => ({
    initializeAuthentication(): Promise<void> {
      if (!self.initPromise) {
        const rootStore = getRootStore(self);

        const { enrollmentId } = rootStore.enrollmentStatus;
        const shippingAddress = rootStore.moduleStores.shippingAndBillingReview!.userShippingAddress;
        const billingAddress = rootStore.moduleStores.shippingAndBillingReview!.billingAddress;
        const personalInfoReview = rootStore.moduleStores.personalInfoReview!;
        const appliedPaymentsReview = rootStore.moduleStores.appliedPaymentsReview!;
        const { creditCardData } = appliedPaymentsReview;

        const order: IOrderFor3DsCheck = {
          MemberId: 0,
          OrderDetails: {
            OrderNumber: enrollmentId,
            Amount: appliedPaymentsReview.amount.toString(),
            Currency: rootStore.reference.currencies[0].code,
            OrderDescription: `${creditCardData.name} - ${enrollmentId}`,
            OrderChannel: 'S'
          },
          Cart: [],
          Consumer: {
            Email1: personalInfoReview.email,
            Email2: personalInfoReview.email,
            ShippingAddress: {
              FullName: creditCardData.name!,
              FirstName: personalInfoReview.firstName,
              MiddleName: personalInfoReview.middleName,
              LastName: personalInfoReview.lastName,
              Address1: shippingAddress.street1!,
              Address2: shippingAddress.street2!,
              Address3: shippingAddress.street3!,
              City: shippingAddress.city!,
              State: shippingAddress.stateProv!,
              PostalCode: shippingAddress.country!,
              CountryCode: shippingAddress.postalCode!,
              Phone1: personalInfoReview.primaryPhone
            },
            BillingAddress: {
              FullName: creditCardData.name!,
              FirstName: personalInfoReview.firstName,
              MiddleName: personalInfoReview.middleName,
              LastName: personalInfoReview.lastName,
              Address1: billingAddress.street1!,
              Address2: billingAddress.street2!,
              Address3: billingAddress.street3!,
              City: billingAddress.city!,
              State: billingAddress.stateProv!,
              PostalCode: billingAddress.postalCode!,
              CountryCode: billingAddress.country!,
              Phone1: personalInfoReview.primaryPhone
            },
            Account: {
              AccountNumber: creditCardData.bin!,
              ExpirationDate: new Date(creditCardData.expirationYear!, creditCardData.expirationMonth!),
              ExpirationMonth: creditCardData.expirationYear!.toString(),
              ExpirationYear: creditCardData.expirationMonth!.toString(),
              NameOnAccount: creditCardData.name!,
              Identity: appliedPaymentsReview.selectedPaymentIdentifier!,
              TokenExToken: appliedPaymentsReview.creditCardData.tokenExToken!
            }
          }
        };

        self.initPromise = new Promise((resolve, reject) => cybersource3ds.init(order, resolve, reject));
      }
      return self.initPromise;
    }
  }))
  .actions(self => ({
    authenticate: flow(function* authenticate(): any {
      yield self.initializeAuthentication();
      self.initPromise = undefined;

      if (!cybersource3ds.isInitCompleted) throw new Error();
      yield new Promise(cybersource3ds.check3DsEnrollment);
    })
  }));

export type Cybersource3dsStore = Instance<typeof Cybersource3dsStore>;
