import debounce from 'lodash/debounce';
import { enrollmentResource } from '../../../../infrastructure/http/EnrollmentResource';
import { TaxIdVerificationResponse } from './models/TaxIdVerificationResponse';

const verifyOnServer = debounce(
  async (
    taxId: string,
    resolve: (message: string | null | false) => void,
    responseCallback: (message: TaxIdVerificationResponse) => void
  ) => {
    const response = await enrollmentResource.post<TaxIdVerificationResponse>('/taxid-verification', { taxId });
    console.log(response.data);
    const responseData = response.data;

    responseCallback(responseData);

    if (!responseData || !responseData.finishedValidation) {
      resolve('taxBusinessTaxIdVerificationError');
    } else if (responseData && !responseData.isValid) {
      resolve('taxBusinessTaxIdNotVerified');
    } else {
      resolve(false);
    }
  },
  200
);

export const verifyTaxId = async (taxId: string, responseCallback: (message: TaxIdVerificationResponse) => void) => {
  return new Promise<string | null | false>(resolve => {
    verifyOnServer(taxId.toUpperCase().trim(), resolve, responseCallback);
  });
};
