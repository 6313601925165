import { Instance, types } from 'mobx-state-tree';
import { FormState, FieldState } from 'formstate';
import { required } from '../../../customer-information/shared/validators/RequiredValidator';

export const CaptchaEntryStore = types
  .model({
    publicKey: types.string,
    token: types.optional(types.string, '')
  })
  .views(self => ({
    get reactForm() {
      return new FormState({
        token: new FieldState(self.token).disableAutoValidation().validators(required())
      });
    },
    canProceed() {
      return self.token != null && self.token != '';
    },
    getDataToSubmit() {
      return { token: self.token };
    }
  }))
  .actions(self => ({
    validateForm() {
      return self.token != null && self.token != '';
    },
    setToken(token: string | null) {
      if(token != null) self.token = token;
    }
  }))
  .named('CaptchaEntryStore');
export type CaptchaEntryStore = Instance<typeof CaptchaEntryStore>;
