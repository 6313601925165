import { Instance, types } from 'mobx-state-tree';

export const ReferrerReviewStore = types
  .model({
    sponsorName: types.maybe(types.string),
    sponsorFirstName: types.maybe(types.string),
    sponsorLastName: types.maybe(types.string),
    hideSponsorLastName: types.optional(types.boolean, false),
    enrollerName: types.maybe(types.string),
    enrollerFirstName: types.maybe(types.string),
    enrollerLastName: types.maybe(types.string),
    hideEnrollerLastName: types.optional(types.boolean, false),
    sponsorId: types.maybe(types.number),
    enrollerId: types.maybe(types.number),
    gaiyoshomenCode: types.maybe(types.string),
    hasReferrer: types.boolean,
    editStepNumber: types.number
  })
  .views(self => ({
    get sponsorFullName(): string {
      return self.hideSponsorLastName && self.sponsorLastName && self.sponsorLastName.length > 0
        ? `${self.sponsorFirstName} ${self.sponsorLastName.substr(0, 1).toUpperCase()}`
        : self.sponsorName!;
    },
    get enrollerFullName(): string {
      return self.hideEnrollerLastName && self.enrollerLastName && self.enrollerLastName.length > 0
        ? `${self.enrollerFirstName} ${self.enrollerLastName.substr(0, 1).toUpperCase()}`
        : self.enrollerName!;
    }
  }));

export type ReferrerReviewStore = Instance<typeof ReferrerReviewStore>;
