import { Instance, types } from 'mobx-state-tree';

import { EnrollmentModule, EnrollmentModules } from '../../enrollment-process/steps-management/model/LayoutModuleGroup';

export const ShareYoungLivingStore = types
  .model('ShareYoungLivingStore', {
    isSharingYoungLiving: types.maybe(types.boolean),
    modules: types.array(types.enumeration<EnrollmentModule>('EnrollmentModules', EnrollmentModules)),
    validated: types.optional(types.boolean, false)
  })
  .views(self => ({
    get supportsDisplay(): boolean {
      return true;
    },
    getDataToSubmit() {
      return {
        isSharingYoungLiving: self.isSharingYoungLiving
      };
    },
    canProceed() {
      return self.isSharingYoungLiving !== undefined;
    },
    get showRequiredError() {
      return self.validated && self.isSharingYoungLiving === undefined;
    },
    get additionalModules(): EnrollmentModule[] {
      if (self.isSharingYoungLiving) {
        return self.modules;
      }
      return [];
    }
  }))
  .actions(self => ({
    validateForm() {
      self.validated = true;
      return Promise.resolve(true);
    },

    selectOption(isSharing: boolean) {
      self.isSharingYoungLiving = isSharing;
    }
  }));

export type ShareYoungLivingStore = Instance<typeof ShareYoungLivingStore>;
