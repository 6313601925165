import { Instance, types } from 'mobx-state-tree';
import { FormState, FieldState } from 'formstate';
import { required } from '../shared/validators/RequiredValidator';

export const GstAgreementEntryStore = types
  .model({
    text: types.maybe(types.string),
    registered: types.optional(types.boolean, false),
    agreementAccepted: types.boolean
  })
  .views(self => ({
    get reactForm() {
      return new FormState({
        registered: new FieldState(self.registered).disableAutoValidation(),
        agreementAccepted: new FieldState(self.agreementAccepted).disableAutoValidation().validators(required())
      });
    },
    canProceed() {
      return !this.reactForm.$.registered.value || !this.reactForm.hasError;
    },
    getDataToSubmit() {
      return {
        agreementAccepted: this.reactForm.$.registered.value && this.reactForm.$.agreementAccepted.value
      };
    },
    isRegistered() {
      return this.reactForm.$.registered.value === true;
    }
  }))
  .actions(self => ({
    validateForm() {
      return self.reactForm.validate();
    },
    afterCreate() {
      self.registered = self.agreementAccepted;
    }
  }))
  .named('GstAgreementEntryStore');

export type GstAgreementEntryStore = Instance<typeof GstAgreementEntryStore>;
