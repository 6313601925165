import debounce from 'lodash/debounce';
import { enrollmentResource } from '../../../../infrastructure/http/EnrollmentResource';

const validateOnServer = debounce(async (taxId: string, taxIdTypeId: number, resolve: (message: string) => void) => {
  const response = await enrollmentResource.post('/account/tax-id-taken', { taxId, taxIdTypeId });
  resolve(response.data && 'UniqueTaxIdError');
}, 1000);

export const noDuplicateTaxIdValidator = async (taxId: string, taxIdTypeId: number) => {
  return new Promise<string>(resolve => {
    validateOnServer(taxId.trim(), taxIdTypeId, resolve);
  });
};
