import { FieldState } from 'formstate';

export function replaceUnallowedCharacters(
  field: FieldState<string>,
  formatRegexForReplace: string | null | undefined
) {
  if (field.value.length === 0 || !formatRegexForReplace) {
    return;
  }

  field.value = field.value.replace(new RegExp(formatRegexForReplace, 'g'), '');
}
