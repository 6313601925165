import { EnrollmentRootStore } from '../../../root/EnrollmentRootStore';
import { enrollmentResource } from '../../../../infrastructure/http/EnrollmentResource';
import { Redirector } from '../../../../infrastructure/redirecting/Redirector';

export async function saveStepAndGoToCustomizeEr(rootStore: EnrollmentRootStore) {
  const enrollmentStep = rootStore.stepsManager.currentStep;
  const saveSucceeded = await enrollmentStep.saveModuleStates();
  if (!saveSucceeded) {
    return;
  }

  try {
    rootStore.spin();
    const response = await enrollmentResource.get('/er-customization-redirect-url');
    Redirector.redirect(response.data.redirectUrl);
  } finally {
    rootStore.unspin();
  }
}

export async function saveStepAndGoToCustomizeErWithPsk(rootStore: EnrollmentRootStore) {
  const enrollmentStep = rootStore.stepsManager.currentStep;
  const saveSucceeded = await enrollmentStep.saveModuleStates();
  if (!saveSucceeded) {
    return;
  }

  try {
    rootStore.spin();
    const response = await enrollmentResource.get('/er-customization-with-psk-redirect-url');
    Redirector.redirect(response.data.redirectUrl);
  } finally {
    rootStore.unspin();
  }
}
