enum PromiseState {
  Pending,
  Fulfilled,
  Rejected
}

interface FulfilledPromiseWithState<T> extends Promise<T> {
  state: PromiseState.Fulfilled;
  result: T;
}

interface RejectedPromiseWithState<T> extends Promise<T> {
  state: PromiseState.Rejected;
  error: any;
}

interface PendingPromiseWithState<T> extends Promise<T> {
  state: PromiseState.Pending;
}

export type PromiseWithState<T> =
  | FulfilledPromiseWithState<T>
  | RejectedPromiseWithState<T>
  | PendingPromiseWithState<T>;

export function withPromiseState<T>(promise: Promise<T>): PromiseWithState<T> {
  const promiseWithState = promise.then(
    result => {
      Object.assign(promiseWithState, {
        state: PromiseState.Fulfilled,
        result
      }) as FulfilledPromiseWithState<T>;
      return result;
    },
    error => {
      Object.assign(promiseWithState, {
        state: PromiseState.Rejected,
        error
      }) as RejectedPromiseWithState<T>;
      throw error;
    }
  );

  return Object.assign(promiseWithState, {
    state: PromiseState.Pending
  }) as PendingPromiseWithState<T>;
}

export const useSuspended = <T>(promise: PromiseWithState<T>) => {
  switch (promise.state) {
    case PromiseState.Rejected:
      throw promise.error;
    case PromiseState.Fulfilled:
      return promise.result;
    default:
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw promise;
  }
};
