import { flow, Instance, types } from 'mobx-state-tree';
import { FieldState, FormState, Validator } from 'formstate';
import { getFormValues } from '@yl/react-forms/dist';
import { AddressModuleForm } from '../../../../address/main-address/module-form/AddressModuleForm';
import { PostalCodeValidation } from '../../../../address/main-address/PostalCodeValidation';
import { AddressEntryFormData } from '../../../../address/main-address/AddressEntryFormData';
import { AddressRow } from '../../../../address/main-address/module-form/AddressRow';
import { maxByteSize } from '../../../../address/main-address/validators/max-byte-size';
import { matchesRegExp } from '../../../../customer-information/shared/validators/RegularExpressionValidator';
import { enrollmentResource } from '../../../../../infrastructure/http/EnrollmentResource';
import { required } from '../../../../customer-information/shared/validators/RequiredValidator';
import { ComponentField } from '../../../../../infrastructure/dynamic-forms/ComponentField';
import { replaceUnallowedCharacters } from '../../../../../infrastructure/forms/InputTextFormatter';
import { Suburb } from '../../../../../reference/Suburb';

export const BillingAddressStore = types
  .model({
    sameAsShippingAddress: types.boolean,
    form: AddressModuleForm,
    fieldRegularExpression: types.string,
    postalCodeValidation: types.maybe(PostalCodeValidation),
    billingAddress: AddressEntryFormData,
    targetCity: types.maybe(types.string),
    targetStateProv: types.maybe(types.string),
  })
  .views(self => ({
    get reactForm() {
      const forms = {} as any;
      const formValues: Record<string, any> = self.billingAddress;
      self.form.rows.forEach((row: AddressRow) => {
        row.formFields.forEach(field => {
          const fieldState = new FieldState(formValues[field.name] || '');
          const validators: Validator<any>[] = [];
          if (field.required) {
            validators.push(required());
          }
          if (field.fieldType === 'Input') {
            validators.push(maxByteSize(field.maxLength));
            validators.push(matchesRegExp(self.fieldRegularExpression, 'InvalidAddress'));
          }

          if (field.name === 'postalCode' && self.postalCodeValidation) {
            validators.push(matchesRegExp(self.postalCodeValidation.validRegEx, 'VerifyPostalcodePatternError'));
            validators.push(matchesRegExp(self.postalCodeValidation.allowedCharactersRegEx, 'InvalidZipCode'));
          }

          fieldState.validators(...validators);
          forms[field.name] = fieldState;
        });
      });
      return new FormState(forms);
    },
    get displayAddressForm() {
      return !self.sameAsShippingAddress;
    },
    canProceed() {
      return this.displayAddressForm ? !this.reactForm.hasError : true;
    },
    getDataToSubmit(): any {
      if (self.sameAsShippingAddress) {
        return null;
      }
      return getFormValues(this.reactForm);
    }
  }))
  .actions(self => ({
    resetFormDirty() {
      return Object.values(self.reactForm.$ as FieldState<any>).forEach(x => {
        x.dirty = false;
      });
    },
    applyFormat: flow(function* debounceFormat(field: ComponentField) {
      yield new Promise(r => setTimeout(r, 1000));
      replaceUnallowedCharacters(self.reactForm.$[field.name], field.formatRegexForReplace);
    }),
    UpdateSuburb(suburb: Suburb) {
      self.reactForm.$.postalCode.value = suburb.postalCode;
    },
    UpdateNoSuburb(noSuburb: boolean) {
      if (noSuburb === true) {
        self.reactForm.$.stateProv.value = null;
        self.reactForm.$.suburb.value = null;
        self.reactForm.$.postalCode.value = null;
      }
      this.setAllowCustomSuburb(noSuburb);
    },

    setAllowCustomSuburb(noSuburb: boolean) {
      self.form.rows.forEach((row: AddressRow) => {
        row.formFields.forEach(field => {
          if (field.name === 'stateProv') {
            field.required = !noSuburb;
          }
          if (field.name === 'suburb') {
            field.required = !noSuburb;
          }
          if (field.name === 'postalCode') {
            field.disabled = !noSuburb;
          }
        });
      });
    },
    setStateProv(stateProv: string) {
      self.targetStateProv = stateProv;
    },
    setCity(city: string) {
      self.targetCity = city;
    }

  }))
  .actions(self => ({
    prepareResults: flow(function* prepareResults() {
      if (!self.displayAddressForm) {
        return Promise.resolve(true);
      }

      yield self.reactForm.validate();

      return !self.reactForm.hasError;
    }),
    validateForm() {
      if (self.displayAddressForm) {
        return self.reactForm.validate();
      }
      return Promise.resolve(true);
    },
    toggleSameAsShippingAddress() {
      self.sameAsShippingAddress = !self.sameAsShippingAddress;
    },
    onCountryChange: flow(function* CountryChange(country: string) {
      const result = yield enrollmentResource.get(`address-entry/${country}`);
      const { form, fieldRegularExpression, postalCodeValidation } = result.data;
      self.billingAddress = AddressEntryFormData.create({ country });
      self.resetFormDirty();
      self.form = form;
      self.fieldRegularExpression = fieldRegularExpression;
      self.postalCodeValidation = postalCodeValidation;
    })
  }));

export type BillingAddressStore = Instance<typeof BillingAddressStore>;
