import { Instance, types, flow } from 'mobx-state-tree';
import { MeoCartItem } from './MeoCartItem';
import { enrollmentResource } from '../../../infrastructure/http/EnrollmentResource';
import { getRootStore } from '../../root/RootStoreUtils';
import { useModuleStore } from '../../enrollment-process/module-management/model/ModuleStoreHook';

export const MeoCartItemSummaryStore = types
  .model('MeoCartItemSummaryStore', {
    meoCartItems: types.array(MeoCartItem),
    orderRequiredModal: false
  })
  .views(self => ({
    canProceed(): boolean {
      const rootStore = getRootStore(self);
      const kitStore = rootStore.moduleStores.enrollmentKitSelection!;
      const totalOrderPV = this.getOrderTotalPV();
      const canProceed =
        rootStore.moduleStores.allowCompletionWithoutOrder ||
        kitStore.selectedKit ||
        totalOrderPV >= kitStore.minimumEnrollmentOrderPv;
      return !!canProceed;
    },
    getOrderTotalPV(): number {
      return self.meoCartItems.reduce((accumulator, item) => {
        return accumulator + item.itemPV * item.quantity;
      }, 0);
    },
    showOrderRequiredWarning(): boolean {
      return self.orderRequiredModal;
    }
  }))
  .actions(self => {
    return {
      refreshMeoCart: flow(function* refreshMeoCart(selectedKit): any {
        const posturl = selectedKit === undefined ? `/update-meo-cart` : `/update-meo-cart/${selectedKit.itemId}`;
        const response = yield enrollmentResource.put(posturl);
        self.meoCartItems = response.data;
        self.orderRequiredModal = !self.canProceed();
      }),
      setRequiredModal(value: boolean) {
        self.orderRequiredModal = value;
      },
      closeRequiredModal() {
        self.orderRequiredModal = false;
      }
    };
  });

export type MeoCartItemSummaryStore = Instance<typeof MeoCartItemSummaryStore>;
