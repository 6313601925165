import { comparer, reaction } from 'mobx';
import { enrollmentResource } from '../../../../infrastructure/http/EnrollmentResource';
import { FieldState } from 'formstate';
import { ObservableTask } from '../../../../external/shared/utils/ObservableTask';

export class GaiyoshomenValidator {

  constructor(
    private readonly gaiyoshomenCodeField: FieldState<string>,
    public onValidationResult?: (result: boolean) => void
  ) {
    reaction(
      () => [gaiyoshomenCodeField.value],
      () => {
        if (this.validatorTask.isRunning) {
          this.validatorTask.runOrRestart();
        }
      },
      { equals: comparer.shallow }
    );
  }

  private validatorResult: boolean | undefined;

  public async validGaiyoshomen() {
    await this.validatorTask.runOrRestart();
    return this.validatorResult ? undefined : 'invalidGaiyoshomenNumber';
  }

  private validatorTask = new ObservableTask(
    async cancelToken => {
      const gaiyoshomenCode = this.gaiyoshomenCodeField.value;
      await new Promise(resolve => setTimeout(resolve, 500));
      if (gaiyoshomenCode !== this.gaiyoshomenCodeField.value) {
        return false;
      }
      const response = await enrollmentResource.get<boolean>(
        `/gaiyoshomen-validation?gaiyoshomenCode=${gaiyoshomenCode}`);
      return response.data;
    },
    result => {
      if (result) {
        this.validatorResult = result;
        this.onValidationResult?.(result);
      }
    }
  );
}
