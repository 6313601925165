import { Instance, types } from 'mobx-state-tree';

export const AddressEntryFormData = types
  .model('AddressEntryFormData', {
    city: types.maybe(types.string),
    /** IsoCode2 */
    country: types.maybe(types.string),
    houseNumber: types.maybe(types.string),
    interiorNumber: types.maybe(types.string),
    neighborhood: types.maybe(types.string),
    postalCode: types.maybe(types.string),
    stateProv: types.maybe(types.string),
    street1: types.maybe(types.string),
    street2: types.maybe(types.string),
    street3: types.maybe(types.string),
    phoneNumber: types.maybe(types.string),
    recipient: types.maybe(types.string),
    isWillCall: types.maybe(types.boolean),
    isPickupPoint: types.maybe(types.boolean),
    isAusPost: types.maybe(types.boolean),
    isShiptoShop:types.optional(types.boolean,false),
    isValidated: types.maybe(types.boolean),
    noSuburb: types.optional(types.boolean, false)
  })
  .views(self => ({
    get asOneLiner() {
      const isJp = self.country === "JP";
      const street = `${self.street1} ${this.sanitized(self.street2)} ${this.sanitized(self.street3)}`.trim();

      return isJp ?
      `${this.sanitized(self.postalCode)} ${this.sanitized(self.stateProv)}, ${this.sanitized(self.city)} ${street}` :
      `${street} ${this.sanitized(self.city)}, ${this.sanitized(self.stateProv)} ${this.sanitized(
        self.postalCode
      )}
      ${self.country}`;
    },
    sanitized(someValue: any | undefined) {
      return someValue || '';
    }
  }));

export type AddressEntryFormData = Instance<typeof AddressEntryFormData>;
