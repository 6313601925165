import { Validator } from 'formstate';

export const validateIf = (condition: (value: any) => boolean, validator: Validator<any>) => {
  return (value: any) => {
    if (condition(value)) {
      return validator(value);
    }

    return false;
  };
};
