import { getParentOfType, Instance, types } from 'mobx-state-tree';
import { ProductKit } from './ProductKit';
import { EnrollmentKitSelectionStore } from './EnrollmentKitStore';
import { Currency } from '../../../../reference/Currency';

const TabTypes = ['Popular', 'Premium', 'Specialty', 'Basic', 'Other'] as const;

export type TabType = (typeof TabTypes)[number];

export type TabLabel = 'PopularPremium' | 'OtherPremium' | 'SpecialtyKits' | 'BasicKits' | 'OtherKits';

export const StarterKitTab = types
  .model({
    id: types.identifierNumber,
    tabType: types.enumeration<TabType>('TabType', TabTypes as any),
    displayStyle: types.enumeration('DisplayStyle', ['Classic', 'Dynamic', 'Grid', 'Basic']),
    productKits: types.array(types.late(() => ProductKit))
  })
  .views(self => ({
    get labelKey(): TabLabel {
      switch (self.tabType) {
        case 'Popular':
          return 'PopularPremium';
        case 'Premium':
          return 'OtherPremium';
        case 'Specialty':
          return 'SpecialtyKits';
        case 'Basic':
          return 'BasicKits';
        case 'Other':
          return 'OtherKits';
        default:
          throw new Error(`TabType ${self.tabType} not supported`);
      }
    },
    get kitStore(): EnrollmentKitSelectionStore {
      return getParentOfType(self, EnrollmentKitSelectionStore);
    },

    get dynamicKitPrice(): { price: number; currency: Currency } {
      if (this.kitStore.selectedKitTab === self && this.kitStore.selectedKit) {
        return this.kitStore.selectedKit.priceWithCurrency;
      }
      return self.productKits[0].priceWithCurrency;
    }
  }));

export type StarterKitTab = Instance<typeof StarterKitTab>;
