import { observable, runInAction } from 'mobx';
import { destroy } from 'mobx-state-tree';

import { EnrollmentStatusFromServer } from './app/customer-enrollment-status/EnrollmentStatus';
import { hookRouterToStepManager } from './app/enrollment-process/steps-management/model/StepsManagerStore';
import { createRootStore } from './app/root/EnrollmentRootStore';
import { bootstrapData, dataPromise as initialDataPromise } from './bootstrap';

async function bootstrapStore(dataPromise: typeof initialDataPromise) {
  const data = await dataPromise;
  if (!data) return undefined;
  const store = createRootStore(data.enrollmentStatus, data.storeSpecificConfig);
  hookRouterToStepManager(store);
  return store;
}

export async function bootstrapNextStore(enrollmentStatus: EnrollmentStatusFromServer) {
  const currentStore = await storePromiseObservable.get();
  const dataPromise = bootstrapData(enrollmentStatus);
  const storePromise = bootstrapStore(dataPromise);
  await storePromise;
  runInAction(() => storePromiseObservable.set(storePromise));
  destroy(currentStore);
}

export const storePromiseObservable = observable.box(bootstrapStore(initialDataPromise));
