import { Instance, types } from 'mobx-state-tree';
import { FormState, FieldState } from 'formstate';
import { required } from '../shared/validators/RequiredValidator';
import { ErKitStore } from '../../order-selection/er-kit-selection/model/ErKitStore';
export const ERAgreementStore = types
  .model({
    showmodel: types.optional(types.boolean, false),
    isErAgreementDismissible: types.optional(types.boolean, false),
    agreementAccepted: types.optional(types.boolean, false)
  })
  .views(self => ({
    get reactForm() {
      return new FormState({
        agreementAccepted: new FieldState(self.agreementAccepted).disableAutoValidation().validators(required())
      });
    }
  }))
  .actions(self => ({
    hideErAgreementModal(erKitStore: ErKitStore): void {
      if (self.agreementAccepted) {
        self.showmodel = false;
      } else if (self.isErAgreementDismissible) {
        erKitStore.updateErAgreementAcceptedValue(false, true);
      }
    }
  }));

export type ERAgreementStore = Instance<typeof ERAgreementStore>;
