import debounce from 'lodash/debounce';
import { enrollmentResource } from '../../../../infrastructure/http/EnrollmentResource';

const validateOnServer = debounce(async (email: string, resolve: (message: string) => void) => {
  const response = await enrollmentResource.post('/account/email-taken', { email });
  resolve(response.data && 'UniqueEmailError');
}, 1000);

export const noDuplicateEmail = async (email: string) => {
  return new Promise<string>(resolve => {
    validateOnServer(email.trim(), resolve);
  });
};
