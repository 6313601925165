import { PaymentStatusViewModel } from '../../../complete-enrollment/models/OrderPaymentStatus';
import { enrollmentResource } from '../../../../infrastructure/http/EnrollmentResource';
import { PlacetopayPaymentStatus } from './PlacetopayPaymentStatus';

export const processPlaceToPay = async (paymentRedirectUrl: string): Promise<PaymentStatusViewModel> => {
  const placetoPayPlugin = (window as { [key: string]: any }).P as any;
  placetoPayPlugin.init(paymentRedirectUrl);
  const result: any = await new Promise(resolve => placetoPayPlugin.on('response', resolve));
  const paymentResult = {
    AlternateOrderId: result.reference,
    ProcessorTransactionId: result.requestId,
    Status: result.status.status as PlacetopayPaymentStatus
  };
  const response = await enrollmentResource.post<PaymentStatusViewModel>(
    'placetopay/complete-enrollment',
    paymentResult
  );
  return response.data;
};
