import { flow, Instance, types } from 'mobx-state-tree';

import { getRootStore } from '../../../../../root/RootStoreUtils';
import {
  EnrollmentPaymentType,
  PaymentMethodProviderType,
  Stripe3DSResultResponse,
  Stripe3DSTransactionResponse
} from '../../../../../../external/shared/api/EnrollmentClient.generated';
import { apiClient } from '../../../../model/credit-card/CreditCard';

export const Stripe3dsStore = types
  .model({
    acsUrl: types.maybe(types.string)
  })
  .volatile(() => ({
    initPromise: undefined as Promise<Stripe3DSTransactionResponse> | undefined,
    threeDsCompleteDeferred: undefined as (() => void) | undefined
  }))
  .views(self => ({
    get isEnabled(): boolean {
      const { appliedPaymentsReview, threeDSecureEntry } = getRootStore(self).moduleStores;
      const { paymentType, paymentMethodProviderType } = appliedPaymentsReview ?? {};
      return (
        threeDSecureEntry!.config.stripe3dsEnabled &&
        paymentType === EnrollmentPaymentType.CreditCard &&
        (!paymentMethodProviderType || paymentMethodProviderType === PaymentMethodProviderType.Stripe)
      );
    }
  }))
  .actions(self => ({
    initializeAuthentication(): Promise<Stripe3DSTransactionResponse> {
      if (!self.initPromise) {
        self.initPromise = apiClient.getStripe3DSParams();
      }
      return self.initPromise;
    }
  }))
  .actions(self => ({
    authenticate: flow(function* authenticate(): any {
      const { is3DSSupported, shouldPerform3DS, acsUrl, paymentIntentId } =
        (yield self.initializeAuthentication()) as Stripe3DSTransactionResponse;

      if (!is3DSSupported || !shouldPerform3DS) return;

      self.acsUrl = acsUrl;

      yield new Promise<void>(resolve => {
        self.threeDsCompleteDeferred = resolve;
      });

      self.acsUrl = undefined;
      self.initPromise = undefined;

      const { status, success }: Stripe3DSResultResponse = yield apiClient.getStripe3DSResult(paymentIntentId);
      if (!success) {
        throw new Error(`3DS status is ${status}`);
      }
    })
  }));

export interface Stripe3dsStore extends Instance<typeof Stripe3dsStore> {}
