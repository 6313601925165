/* eslint-disable import/no-named-as-default-member */
import { Instance, types, flow, addDisposer } from 'mobx-state-tree';
import i18n from 'i18next';
import { reaction } from 'mobx';
import { FieldState, FormState } from 'formstate';

import { StoreLanguage } from './StoreLanguage';
import { getRootStore } from '../../root/RootStoreUtils';
import { required } from '../../customer-information/shared/validators/RequiredValidator';
import { enrollmentResource } from '../../../infrastructure/http/EnrollmentResource';
import { Language } from '../../../reference/Language';

export const LocaleManager = types
  .model({
    languages: types.array(StoreLanguage),
    language: types.reference(StoreLanguage),
    showLocaleChooser: false,
    showMarketChangeWarning: false
  })
  .views(self => ({
    get reactForm() {
      return new FormState({
        country: new FieldState('').disableAutoValidation().validators(required()),
        language: new FieldState('').disableAutoValidation().validators(required())
      });
    },
    get countryChanged(): boolean {
      return this.reactForm.$.country.$ !== getRootStore(self).enrollmentStatus.countryIso;
    },
    get languageOptions(): Language[] | undefined {
      const languages = getRootStore(self).reference.getCountryWithLanguages(this.reactForm.$.country.$)?.languages;
      return this.countryChanged
        ? languages
        : languages?.filter(l => self.languages.find(x => x.locale === l.localeCode));
    }
  }))
  .actions(self => ({
    openLocaleChooser() {
      const { countryIso } = getRootStore(self).enrollmentStatus;
      self.reactForm.$.country.reset(countryIso);
      self.reactForm.$.language.reset(self.language.locale);
      self.showLocaleChooser = true;
    },
    hideLocaleChooser() {
      self.showLocaleChooser = false;
    },
    confirmLocaleChange: flow(function* submitLocaleChange() {
      const rootStore = getRootStore(self);
      const classicErKitSelectionStore = rootStore.moduleStores.enrollmentKitSelection;
      self.showLocaleChooser = false;
      const sharedLocale = 'en-EU';
      const isSharedLocaleChanged =
        self.language.locale === sharedLocale || self.reactForm.$.language.$ === sharedLocale;
      const isCountryChanged = self.countryChanged;

      if (isCountryChanged || isSharedLocaleChanged) {
        self.showMarketChangeWarning = true;
      } else if (self.reactForm.$.language.$ !== self.language.locale) {
        rootStore.spin();
        try {
          yield enrollmentResource.post(`enrollment-status/locale/${self.reactForm.$.language.$}`, {});
          yield i18n.changeLanguage(self.reactForm.$.language.$);
          classicErKitSelectionStore?.refreshCart();
          self.language = self.reactForm.$.language.$ as any;
        } finally {
          rootStore.unspin();
        }
      }
    }),
    hideMarketChangeWarning() {
      self.showMarketChangeWarning = false;
    }
  }))
  .actions(self => ({
    afterCreate() {
      i18n.changeLanguage(self.language.locale);
      addDisposer(
        self,
        reaction(
          () => self.languageOptions,
          languageOptions => {
            if (languageOptions?.every(x => x.localeCode !== self.reactForm.$.language.value)) {
              self.reactForm.$.language.reset('');
            }
          }
        )
      );
    }
  }));

export type LocaleManager = Instance<typeof LocaleManager>;
