import { Instance, types} from 'mobx-state-tree';
import { FormState, FieldState } from 'formstate';
import { required } from '../shared/validators/RequiredValidator';

export const ExceliaAgreementEntryStore = types
  .model({
    document: types.string,
    customerLegacyId: types.number,
    agreementAccepted: types.optional(types.boolean, false),
    documentUpdatedToEr: types.optional(types.boolean, false)
  })
  .views(self => ({
    get reactForm() {
      return new FormState({
        agreementAccepted: new FieldState(self.agreementAccepted).disableAutoValidation().validators(required())
      });
    },
    canProceed() {
      return !this.reactForm.hasError;
    },
    getDataToSubmit() {
      return { agreementAccepted: this.reactForm.$.agreementAccepted.value };
    },
    getFileURL(data: string) {
      const byteCharacters = atob(data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });
        return URL.createObjectURL(blob);
    }
  }))
  .actions(self => ({
    validateForm() {
      return self.reactForm.validate();
    },
    setAgreementAccepted() {
      self.agreementAccepted = true;
    },
    updateModel(data: any, shouldUpdateToEr: boolean){
      self.document = data.document;
      self.customerLegacyId = data.customerLegacyId;
      self.documentUpdatedToEr = shouldUpdateToEr;
    },
    downloadDocument() {
      const byteCharacters = atob(self.document);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `ExceliaAgreement.pdf`,
      );

      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    }
  }))
  .named('ExceliaAgreementEntryStore');

export type ExceliaAgreementEntryStore = Instance<typeof ExceliaAgreementEntryStore>;
