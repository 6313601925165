import { FieldState, Validator } from 'formstate';

type ValidationValue = string | number;
type ValueCombiner = (values: ValidationValue[]) => ValidationValue;

export function combineFields(otherFields: FieldState<any>[], combineValues: ValueCombiner, validator: Validator<any>) {
  return (value: string | number) => {
    const otherValues = otherFields.map(x => x.value);
    if (otherValues.includes('')) {
      return '';
    }
    return validator(combineValues([value, ...otherValues]));
  };
}
