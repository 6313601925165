import { Instance, types } from 'mobx-state-tree';
import { getRootStore } from '../../../root/RootStoreUtils';

export const ErKitFormData = types.model('ErKitFormData', {
  IsEnrollingInEr: types.boolean,
  UsePremiumStarterKitAsErOrder: types.boolean,
  selectedKitId: types.maybe(types.number)
});

export const ErUpsellModalStore = types
  .model('ErUpsellModalStore', {
    showErUpsellModal: types.optional(types.boolean, false)
  })
  .volatile(() => ({
    erUpsellDeferred: undefined as ((value: boolean) => void) | undefined
  }))
  .actions(self => ({
    show(): Promise<boolean> {
      self.showErUpsellModal = true;
      return new Promise(resolve => {
        self.erUpsellDeferred = resolve;
      });
    },
    close(continueWithoutKit: boolean) {
      self.showErUpsellModal = false;
      if (self.erUpsellDeferred) {
        self.erUpsellDeferred(continueWithoutKit);
      }
      self.erUpsellDeferred = undefined;
    },
    showUpsellIfNecessary(erKitSelected: boolean): Promise<boolean> {
      const root = getRootStore(self);
      const enrollmentKitSelection = root.moduleStores.enrollmentKitSelection;
      if (!enrollmentKitSelection) {
        return Promise.resolve(true);
      }
      if (!enrollmentKitSelection.selectedKit || erKitSelected) {
        return Promise.resolve(true);
      }
      return this.show();
    }
  }));

export type ErUpsellModalStore = Instance<typeof ErUpsellModalStore>;
