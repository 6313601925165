import { Instance, types } from 'mobx-state-tree';
import { getRootStore } from '../../../root/RootStoreUtils';

export const moduleMapping = {
  SocialLinkEntry: 'socialLinkEntry' as const,
  CreateSocialLinkButton: 'createSocialLinkButton' as const,
  SocialLinkCreatorBanner: 'socialLinkCreatorBanner' as const,
  SocialLinkErKitSelection: 'socialLinkErKitSelection' as const,
  VisitMainsite: 'visitMainsite' as const,
  EnrollmentKitSelection: 'enrollmentKitSelection' as const,
  ErKitSelection: 'erKitSelection' as const,
  ClassicErKitSelection: 'classicErKitSelection' as const,
  AddProducts: 'addProducts' as const,
  MeoKitSelection: 'meoKitSelection' as const,
  NameEntry: 'nameEntry' as const,
  StudentEntry: 'studentEntry' as const,
  AgeEntry: 'ageEntry' as const,
  CoApplicantEntry: 'coApplicantEntry' as const,
  AddressEntry: 'addressEntry' as const,
  EmailEntry: 'emailEntry' as const,
  PhoneEntry: 'phoneEntry' as const,
  AccountCredentials: 'accountCredentialsEntry' as const,
  PinEntry: 'pinEntry' as const,
  TaxIdEntry: 'taxIdEntry' as const,
  SponsorEnroller: 'sponsorEnrollerEntry' as const,
  MemberAgreement: 'memberAgreementEntry' as const,
  ShippingMethodSelection: 'shippingMethodSelection' as const,
  ShippingAddressEntry: 'shippingAddressEntry' as const,
  BillingEntry: 'billingEntry' as const,
  OrderSummary: 'orderSummary' as const,
  MembershipReview: 'membershipReview' as const,
  PersonalInfoReview: 'personalInfoReview' as const,
  ShippingAndBillingReview: 'shippingAndBillingReview' as const,
  OrderTotalReview: 'orderTotalReview' as const,
  AppliedPaymentsReview: 'appliedPaymentsReview' as const,
  PromoVoucherEntry: 'promoVoucherEntry' as const,
  GenderEntry: 'genderEntry' as const,
  AddressValidator: 'addressValidator' as const,
  MemberAgreementEntry: 'memberAgreementEntry' as const,
  CompleteEnrollment: 'completeEnrollmentProcessor' as const,
  CompleteNoPurchaseEnrollment: 'completeEnrollmentProcessor' as const,
  GstAgreement: 'gstAgreementEntry' as const,
  TaxFormsAgreement: 'taxFormsAgreementEntry' as const,
  ShareYoungLiving: 'shareYoungLivingEntry' as const,
  PreferencesEntry: 'preferencesEntry' as const,
  ReferrerReview: 'referrerReivew' as const,
  ContinueEnrollment: 'continueEnrollment' as const,
  DirectMarketingAgreementEntry: 'directMarketingAgreementEntry' as const,
  PrivacyPolicyAgreementEntry: 'privacyPolicyAgreementEntry' as const,
  ExceliaAgreementEntry: 'exceliaAgreementEntry' as const,
  ConfirmMemberInfoEntry: 'confirmMemberInfoEntry' as const,
  CaptchaEntry: 'captchaEntry' as const,
  CustomerTypeEntry: 'customerTypeEntry' as const,
  ReviewTopBanner: 'reviewTopBanner' as const
};

export type EnrollmentModule = keyof typeof moduleMapping;
export const EnrollmentModules = Object.keys(moduleMapping) as EnrollmentModule[];

export const LayoutModuleGroup = types
  .model('LayoutModuleGroup', {
    label: types.maybe(types.string),
    modules: types.array(types.enumeration<EnrollmentModule>('EnrollmentModules', EnrollmentModules))
  })
  .views(self => ({
    get isCompleted(): boolean {
      const root = getRootStore(self);
      const completed = root.moduleStores.completedModules;
      return self.modules.every(module => completed.includes(module));
    }
  }));

export type LayoutModuleGroup = Instance<typeof LayoutModuleGroup>;
