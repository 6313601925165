import { Instance, types } from 'mobx-state-tree';

export const MembershipReviewStore = types
  .model({
    customerType: types.string
  })
  .views(self => ({}))
  .actions(self => ({}));

export type MembershipReviewStore = Instance<typeof MembershipReviewStore>;
