import { Instance, types } from 'mobx-state-tree';

export const UpgradeComponentInfo = types.model('UpgradeComponentInfo', {
  partNumber: types.identifier,
  smallImageUrl: types.string,
  largeImageUrl: types.string,
  title: types.string
});

export type UpgradeComponentInfo = Instance<typeof UpgradeComponentInfo>;
