import { Instance, types } from 'mobx-state-tree';
import { State } from './State';

export const Country = types.model('Country', {
  isoCode2: types.string,
  isoCode3: types.string,
  name: types.string,
  states: types.optional(types.array(State), [])
});
export type Country = Instance<typeof Country>;
