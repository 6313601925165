import { Instance, types } from 'mobx-state-tree';
import { BusinessTaxEntry } from '../tax-context-types/business/BusinessTaxEntry';
import { IndividualTaxEntry } from '../tax-context-types/individual/IndividualTaxEntry';
import { MexicoCurpTaxEntry } from '../tax-context-types/mexico/MexicoCurpTaxEntry';

export const TaxIdEntryOption = types
  .model('TaxIdEntryOption', {
    taxIdTypeId: types.number,
    individual: types.maybe(IndividualTaxEntry),
    business: types.maybe(BusinessTaxEntry),
    mexicoCurp: types.maybe(MexicoCurpTaxEntry)
  })
  .views(self => ({
    get entryForCollectingCombinedTaxId() {
      if (self.business) {
        return self.business;
      }
      if (self.individual) {
        return self.individual;
      }
      throw Error('Entry not supported for collecting tax id');
    }
  }));

export type TaxIdEntryOption = Instance<typeof TaxIdEntryOption>;
